import React from "react";
import Slider from "react-slick";
import { Slider6 } from "../../services/script";
import "./style.scss";
import CategoriesCard from "./categoryItem";

const CategorySlideComponent = ({ categories }) => {

  return (
    <div className="row category-slider-component p-3">
      <div className="col" style={{ padding: "0px" }}>
        <Slider {...Slider6} className="product-m no-arrow">
          {categories.map((category, index) => (
            <div key={index} style={{ width: "100% !important" }}>
              <CategoriesCard
                category={category}
                onClick={() => this.handleCategories(category)}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
    // <div className="row category-slide-component">
    //   <div className="col">
    //     <Slider {...Slider6} className="slide-6 no-arrow">
    //       {categories.map((cat) => (
    //         <CategoriesCard category={cat} />
    //           <div className="category-block">
    //             <Link to={cat.path}>
    //               <div className="category-image">
    //                 <img src={`${cat.image}`} alt="" />
    //               </div>
    //             </Link>
    //             <div className="category-details">
    //               <Link to={cat.path}>
    //                 <h5>{cat.title}</h5>
    //               </Link>
    //             </div>
    //           </div>
    //       ))}
    //     </Slider>
    //   </div>
    // </div>
  );
};

export default CategorySlideComponent;
