import React, { Component } from "react";
import "./style.scss";
import { userSignup, userLogin } from "../../actions/user";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
import SimpleReactValidator from "simple-react-validator";
import { LoaderComponent } from "../../components/Ui/loader";
import _ from "lodash";
import { toast } from "react-toastify";
import { userAttr } from "../../api/formData";
import { Link } from "react-router-dom";

class SignupRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      phone: "",
      password: "",
      email: "",
      firstName: "",
      lastName: "",
      payment_type: "NA",
      package_name: "NA",
      package_amount: "12345",
      checked: true,
    };

    this.validator = new SimpleReactValidator();
  }

  setStateFromInput = (event) => {
    var obj = {};
    obj[event.target.name] = event.target.value;
    this.setState(obj);
  };

  userSignup = () => {
    if (this.validator.allValid()) {
      if (this.state.checked) {
        this.setState({ isLoading: true });
        let payload = {
          ...userAttr,
          ...this.state,
        };
        delete payload.isLoading;
        this.props
          .userSignup(payload)
          .then((response) => {
            if (response["data"]["status"]) {
              toast.success("Account created successfully");
              this.props
                .userLogin(this.state.phone, this.state.password)
                .then(() => {
                  this.setState({ isLoading: false });
                  this.props.history.replace("/");
                });
            } else {
              this.setState({ isLoading: false });
              toast.warn(response["data"]["message"]);
            }
          })
          .catch((error) => {
            this.setState({ isLoading: false });
            toast.error("Something went to wrong please try again..");
          });
      } else {
        toast.warn(
          "Please read and agree to the Terms and Conditions and privay policy by checking the box"
        );
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    return (
      <div>
        {/*Login section*/}
        <section className="login-page section-b-space">
          <div className="container">
            <div className="login-container">
              <h3>Create Account</h3>
              <div className="theme-card">
                {this.state.isLoading ? (
                  <LoaderComponent />
                ) : (
                  <form className="theme-form">
                    <div className="row">
                      <div className="col-6">
                        <div
                          className="form-group p-0"
                          style={{ width: "100%" }}
                        >
                          <label htmlFor="firstName">First Name</label>
                          <input
                            type="tel"
                            className="form-control"
                            name="firstName"
                            value={this.state.firstName}
                            placeholder="Frist Name"
                            onChange={this.setStateFromInput}
                          />
                          {this.validator.message(
                            "Frist Name",
                            this.state.firstName,
                            "required"
                          )}
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <label htmlFor="lastName">Last Name</label>
                          <input
                            type="text"
                            className="form-control"
                            name="lastName"
                            value={this.state.lastName}
                            placeholder="Last Name"
                            onChange={this.setStateFromInput}
                          />
                          {this.validator.message(
                            "lastName",
                            this.state.lastName,
                            "required"
                          )}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label htmlFor="phone">Mobile</label>
                          <input
                            type="text"
                            className="form-control"
                            name="phone"
                            value={this.state.phone}
                            placeholder="Mobile"
                            onChange={this.setStateFromInput}
                          />
                          {this.validator.message(
                            "phone",
                            this.state.phone,
                            "required|phone"
                          )}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label htmlFor="email">Email</label>
                          <input
                            type="text"
                            className="form-control"
                            name="email"
                            value={this.state.email}
                            placeholder="Email Address"
                            onChange={this.setStateFromInput}
                          />
                          {this.validator.message(
                            "email",
                            this.state.email,
                            "required|email"
                          )}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label htmlFor="password">Password</label>
                          <input
                            type="password"
                            className="form-control"
                            name="password"
                            value={this.state.password}
                            placeholder="Password"
                            onChange={this.setStateFromInput}
                          />
                          {this.validator.message(
                            "password",
                            this.state.password,
                            "required|min:8"
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "20px",
                      }}
                    >
                      <button
                        type="button"
                        onClick={this.userSignup}
                        className="btn btn-solid"
                      >
                        Create Account
                      </button>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "20px",
                      }}
                    >
                      <p>
                        Already have an account? <Link to="/auth">Sign In</Link>
                      </p>
                    </div>
                    <div
                      style={{
                        // display: "flex",
                        // alignItems: "center",
                        // justifyContent: "center",
                        marginTop: "20px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="term_condition"
                        name="term_condition"
                        checked={this.state.checked}
                        onChange={(e) => {
                          const { checked } = e.target;
                          this.setState({ checked });
                        }}
                      />
                      <a
                        className="ml-2"
                        style={{ textDecoration: "underline" }}
                        href="https://psgmyperfectfit.co.in/terms-conditions"
                      >
                        I accept the Terms of Service
                      </a>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapToStateProps = (state, ownProps) => {
  let path = "/";
  if (!_.isEmpty(ownProps.location.state)) {
    if (!_.isNil(ownProps.location.state.from)) {
      path = ownProps.location.state.from;
    }
  }
  return {
    redirectPath: path,
  };
};

const mapToDispatchProps = (dispatch) => {
  return {
    userSignup: (email, password) => dispatch(userSignup(email, password)),
    userLogin: (phone, password) => dispatch(userLogin(phone, password)),
  };
};

export default connect(
  mapToStateProps,
  mapToDispatchProps
)(SignupRoute);
