import React, { Component } from "react";
import { Link } from "react-router-dom";
// import Slider from "react-slick";
import { roundDecimals } from "../../services";
import "./style.scss";
// import Modal from "react-responsive-modal";
import _ from "lodash";
import "react-toastify/dist/ReactToastify.min.css";
import { toast } from "react-toastify";
import Modal from "react-responsive-modal";

class DetailsWithPrice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      quantity: 1,
      stock: "InStock",
      nav3: null,
      size: {},
    };
  }

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  componentDidMount() {
    this.setState({
      nav3: this.slider3,
    });
  }

  minusQty = () => {
    if (this.state.quantity > 1) {
      this.setState({ stock: "InStock" });
      this.setState({ quantity: this.state.quantity - 1 });
    }
  };

  plusQty = () => {
    if (this.props.item.stock >= this.state.quantity) {
      this.setState({ quantity: this.state.quantity + 1 });
    } else {
      this.setState({ stock: "Out of Stock !" });
    }
  };
  changeQty = (e) => {
    this.setState({ quantity: parseInt(e.target.value) });
  };

  addToCart = (item) => {
    let discPrice = 0;
    let size = "";
    let price = 0;

    if (_.has(item, "options")) {
      if (_.isEmpty(item.options)) {
        discPrice = roundDecimals(item.discPrice);
        price = roundDecimals(item.price);
        size = "";
      } else {
        if (_.isEmpty(this.state.size)) {
          discPrice = roundDecimals(item.options[0]["options"][0].discPrice);
          price = roundDecimals(item.options[0]["options"][0].price);
          size = item.options[0]["options"][0].value;
        } else {
          discPrice = roundDecimals(this.state.size.discPrice);
          price = roundDecimals(this.state.size.price);
          size = this.state.size.value;
        }
      }
    }

    let cartItem = {
      producttypeId: item.producttype[0]["_id"],
      itemId: item._id,
      name: item.name,
      styles: [],
      qty: 1,
      size: size,
      price: price,
      discPrice: discPrice,
      disc: item.discount,
      isPer: item.isPer ? item.isPer : false,
      deliveryDays: item.deliveryDays,
      catId: item.category[0]["_id"],
      images: item.images,
    };
    if (!_.isEmpty(item.producttype)) {
      if (item.producttype[0]["name"] === "Custom Made") {
        this.props.addToCartClicked(cartItem, this.state.quantity);
      } else if (item.producttype[0]["name"] === "Ready Made") {
        if (!_.isEmpty(item.options)) {
          if (_.isEmpty(this.state.size)) {
            toast.warn("Please select size");
          } else {
            this.props.addToCartClicked(cartItem, this.state.quantity);
          }
        } else {
          this.props.addToCartClicked(cartItem, this.state.quantity);
        }
      }
    }
  };

  render() {
    const {
      symbol,
      item,
      addToCartClicked,
      BuynowClicked,
      addToWishlistClicked,
    } = this.props;

    var colorsnav = {
      slidesToShow: 6,
      swipeToSlide: true,
      arrows: false,
      dots: false,
      focusOnSelect: true,
    };

    return (
      <div className="col-lg-6 rtl-text">
        <div className="product-right">
          <h3> {item.name}</h3>

          {_.has(item, "options") && (
            <React.Fragment>
              {_.isEmpty(item.options) ? (
                <React.Fragment>
                  <h4 className="mt-2">
                    {item.price !== item.discPrice && (
                      <del>
                        <span className="money">
                          {symbol}
                          {roundDecimals(item.price)}
                        </span>
                      </del>
                    )}
                  </h4>
                  <h3>
                    {" "}
                    {symbol}
                    {roundDecimals(item.discPrice)}{" "}
                  </h3>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {_.isEmpty(this.state.size) ? (
                    <React.Fragment>
                      <h4>
                        {item.options[0]["options"][0].price !==
                          item.options[0]["options"][0].discPrice && (
                          <del>
                            {symbol}
                            {roundDecimals(item.options[0]["options"][0].price)}
                          </del>
                        )}
                      </h4>
                      <h3 style={{ fontWeight: "bold" }}>
                        {symbol}
                        {/* {item.price - (item.price * item.discount) / 100}{" "} */}
                        {roundDecimals(item.options[0]["options"][0].discPrice)}
                        /-
                      </h3>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <h4>
                        {this.state.size.price !==
                          this.state.size.discPrice && (
                          <del>
                            {symbol} {this.state.size.price}
                          </del>
                        )}
                        {/* <span>{item.discount}% off</span> */}
                      </h4>
                      <h3 style={{ fontWeight: "bold" }}>
                        {symbol}
                        {/* {item.price - (item.price * item.discount) / 100}{" "} */}
                        {roundDecimals(this.state.size.discPrice)}/-
                      </h3>
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}

          {!_.isEmpty(item.producttype) && (
            <React.Fragment>
              {item.producttype[0]["name"] === "Custom Made" ? (
                <React.Fragment />
              ) : (
                <React.Fragment>
                  <div className="product-description border-product">
                    {!_.isEmpty(item.options) && (
                      <div>
                        <h6 className="product-title size-text">
                          {_.isEmpty(this.state.size)
                            ? "Select Size"
                            : `Select Size(${this.state.size.value})`}
                          <span>
                            <a
                              onClick={() => {
                                this.onOpenModal();
                              }}
                              className="product-title size-text text-center"
                              style={{ color: "#007bff" }}
                            >
                              Size Chart
                            </a>
                          </span>
                        </h6>
                        <div
                          className="modal fade"
                          id="sizemodal"
                          tabIndex="-1"
                          role="dialog"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div
                            className="modal-dialog modal-dialog-centered"
                            role="document"
                          >
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5
                                  className="modal-title"
                                  id="exampleModalLabel"
                                >
                                  Size Chart
                                </h5>
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div className="modal-body">
                                <img
                                  src={`${
                                    process.env.PUBLIC_URL
                                  }/assets/images/size-chart.jpg`}
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="size-box">
                          <ul>
                            {item.options[0]["options"].map((size, i) => {
                              return (
                                <li key={i}>
                                  <a
                                    className={
                                      item.options[0]["options"][i]["_id"] ===
                                      this.state.size._id
                                        ? "active_size"
                                        : ""
                                    }
                                    onClick={() =>
                                      this.setState({ size: size, index: i })
                                    }
                                  >
                                    {size.value}
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          )}

          <div className="product-buttons">
            <a className="btn btn-solid" onClick={() => this.addToCart(item)}>
              add to cart
            </a>
          </div>
          <div className="border-product">
            <h6 className="product-title">product details</h6>
            <p>{item.description}</p>
          </div>
          <div className="border-product" />
        </div>

        <div className="border-product mt-3">{this.props.children}</div>

        <Modal open={this.state.open} onClose={this.onCloseModal} center>
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Size Chart
                </h5>
              </div>
              <div className="modal-body">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/size-chart.jpg`}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default DetailsWithPrice;
