import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { ScrollContext } from "react-router-scroll-4";
import { IntlReducer as Intl, IntlProvider } from "react-redux-multilingual";
import { getMasterAttributes } from "./actions/products";

import _ from "lodash";

// import { getUserDetails } from "./actions/user";
import "./index.scss";

// Import custom components
import store from "./store";
import translations from "./constants/translations";

// import Landing from "./components/landing";

// Layouts
// import Fashion from "./components/layouts/fashion/main";
// import Vegetables from "./components/layouts/vegetables/main";
// import Kids from "./components/layouts/kids/main";
// import Pets from "./components/layouts/pets/main";
// import Furniture from "./components/layouts/furniture/main";
// import Watch from "./components/layouts/watch/main";
// import Beauty from "./components/layouts/beauty/main";
// import Electronic from "./components/layouts/electronic/main";

//Collection Pages
// import CollectionLeftSidebar from "./components/collection/collection-left-sidebar";
// import CollectionNoSidebar from "./components/collection/collection-no-sidebar";
// import CollectionRightSidebar from "./components/collection/collection-right-sidebar";
// import CollectionFullWidth from "./components/collection/collection-full-width";
// import CollectionMetro from "./components/collection/collection-metro";

// Product Pages
// import LeftSideBar from "./components/products/left-sidebar";
// import RightSideBar from "./components/products/right-sidebar";
// import NoSideBar from "./components/products/no-sidebar";
// import LeftImage from "./components/products/left-image";
// import RightImage from "./components/products/right-image";
// import Accordian from "./components/products/accordian";
// import ColumnLeft from "./components/products/column-left";
// import ColumnRight from "./components/products/column-right";
// import Column from "./components/products/column";
// import Vertical from "./components/products/vertical";

// Features
import Layout from "./components/app";
// import Cart from "./components/cart";
// import Compare from "./components/compare/index";
// import wishList from "./components/wishlist";
// import checkOut from "./components/checkout";
// import orderSuccess from "./components/checkout/success-page";

// Extra Pages
// import aboutUs from "./components/pages/about-us";
// import PageNotFound from "./components/pages/404";
// import lookbook from "./components/pages/lookbook";
// import Login from "./components/pages/login";
// import Register from "./components/pages/register";
// import Search from "./components/pages/search";
// import Collection from "./components/pages/collection";
// import ForgetPassword from "./components/pages/forget-password";
// import Contact from "./components/pages/contact";
// import Dashboard from "./components/pages/dashboard";
// import Faq from "./components/pages/faq";

// Blog Pages
// import RightSide from "./components/blogs/right-sidebar";
// import Details from "./components/blogs/details";
// import BlogPage from "./components/blogs/blog-page";

// Theme Element
// import ElementTitle from "./components/features/theme/element-title";
// import ElementBanner from "./components/features/theme/element-banner";
// import ElementSlider from "./components/features/theme/element-slider";
// import ElementCategory from "./components/features/theme/element-category";
// import ElementService from "./components/features/theme/element-service";
// import ElementRatio from "./components/features/theme/element-ratio";

// Product Elements
// import ElementProductBox from "./components/features/product/element-product-box";
// import ElementProductSlider from "./components/features/product/element-product-slider";
// import ElementProductNoSlider from "./components/features/product/element-product-no-slider";
// import ElementMultipleSlider from "./components/features/product/element-multiple-slider";
// import ElementProductTab from "./components/features/product/element-product-tab";

// Portfolio Features
// import GridCols from "./components/features/portfolio/grid-cols";
// import MasonaryGridCols from "./components/features/portfolio/masonary-grid-cols";
// Gaurd

// import AuthGaurd from "./hoc/AuthGaurd";

// Routes
import HomeRoute from "./routes/home/Home";
import ProductDetailsRoute from "./routes/productDetails/ProductDetails";
import CustomShirtsRoute from "./routes/products/CustomShirts";
import CustomTrousersRoute from "./routes/products/CustomTrousers";
import CustomSuitsRoute from "./routes/products/CustomSuits";
import CustomChinos from "./routes/products/CustomChinos";
import CustomKrthas from "./routes/products/CustomKurtas";
import CustomIndoWestren from "./routes/products/CustomIndowestern";
import CustomSadri from "./routes/products/CustomSadri";
import CustomBlazers from "./routes/products/CustomBlazers";
import ShoesRoute from "./routes/products/Shoes";
import CartRoute from "./routes/cart/Cart";
import AddressesRoute from "./routes/address/Address";
import UserLoginRoute from "./routes/login/Login";
import UserSignupRoute from "./routes/Signup/Signup";
import ResetPasswordRoute from "./routes/ForgotPassword/Forgotpassword";
import CheckOutRoute from "./routes/checkout/Checkout";
import OrderRoute from "./routes/orders/Orders";
import OrderSuccess from "./routes/orders/order-summary";

class Root extends React.Component {
  componentDidUpdate() {
   
  }

  render() {
    
    store.dispatch(getMasterAttributes());
    return (
      <Provider store={store}>
        <IntlProvider translations={translations} locale="en">
          <BrowserRouter basename={"/"}>
            <ScrollContext>
              <Switch>
                <Layout>
                  <Route
                    exact
                    strict
                    path={`${process.env.PUBLIC_URL}/`}
                    component={HomeRoute}
                  />
                  <Route
                    exact
                    strict
                    path={`${process.env.PUBLIC_URL}/psguser/:id`}
                    component={HomeRoute}
                  />
                  <Route
                    exact
                    strict
                    path={`${process.env.PUBLIC_URL}/shop/shirts`}
                    component={CustomShirtsRoute}
                  />
                  <Route
                    exact
                    strict
                    path={`${process.env.PUBLIC_URL}/shop/shirts/:id`}
                    component={CustomShirtsRoute}
                  />
                  <Route
                    exact
                    strict
                    path={`${process.env.PUBLIC_URL}/shop/shoes`}
                    component={ShoesRoute}
                  />
                  <Route
                    exact
                    strict
                    path={`${process.env.PUBLIC_URL}/shop/shoes/:id`}
                    component={ShoesRoute}
                  />
                  <Route
                    exact
                    strict
                    path={`${process.env.PUBLIC_URL}/shop/trousers`}
                    component={CustomTrousersRoute}
                  />
                  <Route
                    exact
                    strict
                    path={`${process.env.PUBLIC_URL}/shop/trousers/:id`}
                    component={CustomTrousersRoute}
                  />
                  <Route
                    exact
                    strict
                    path={`${process.env.PUBLIC_URL}/shop/suits`}
                    component={CustomSuitsRoute}
                  />
                  <Route
                    exact
                    strict
                    path={`${process.env.PUBLIC_URL}/shop/suits/:id`}
                    component={CustomSuitsRoute}
                  />
                  <Route
                    exact
                    strict
                    path={`${process.env.PUBLIC_URL}/shop/chinos`}
                    component={CustomChinos}
                  />
                  <Route
                    exact
                    strict
                    path={`${process.env.PUBLIC_URL}/shop/chinos/:id`}
                    component={CustomChinos}
                  />
                  <Route
                    exact
                    strict
                    path={`${process.env.PUBLIC_URL}/shop/kurthas`}
                    component={CustomKrthas}
                  />
                  <Route
                    exact
                    strict
                    path={`${process.env.PUBLIC_URL}/shop/kurthas/:id`}
                    component={CustomKrthas}
                  />
                  <Route
                    exact
                    strict
                    path={`${process.env.PUBLIC_URL}/shop/indo-westren`}
                    component={CustomIndoWestren}
                  />
                  <Route
                    exact
                    strict
                    path={`${process.env.PUBLIC_URL}/shop/indo-westren/:id`}
                    component={CustomIndoWestren}
                  />
                  <Route
                    exact
                    strict
                    path={`${process.env.PUBLIC_URL}/shop/sadris`}
                    component={CustomSadri}
                  />
                  <Route
                    exact
                    strict
                    path={`${process.env.PUBLIC_URL}/shop/sadris/:id`}
                    component={CustomSadri}
                  />
                  <Route
                    exact
                    strict
                    path={`${process.env.PUBLIC_URL}/shop/blazers`}
                    component={CustomBlazers}
                  />
                  <Route
                    exact
                    strict
                    path={`${process.env.PUBLIC_URL}/shop/blazers/:id`}
                    component={CustomBlazers}
                  />
                  <Route
                    exact
                    strict
                    path={`${process.env.PUBLIC_URL}/product/:id`}
                    component={ProductDetailsRoute}
                  />
                  <Route
                    exact
                    strict
                    path={`${process.env.PUBLIC_URL}/cart`}
                    component={CartRoute}
                  />
                  <Route
                    exact
                    strict
                    path={`${process.env.PUBLIC_URL}/reset-password`}
                    component={ResetPasswordRoute}
                  />

                  <Route
                    exact
                    strict
                    path={`${process.env.PUBLIC_URL}/addresses`}
                    render={() => {
                      if (_.isEmpty(store.getState().userData.userData)) {
                        return (
                          <Redirect
                            to={{
                              pathname: "/auth",
                              state: { from: "/addresses" },
                            }}
                          />
                        );
                      } else {
                        return <AddressesRoute />;
                      }
                    }}
                  />
                  <Route
                    exact
                    strict
                    path={`${process.env.PUBLIC_URL}/checkout`}
                    render={() => {
                      if (_.isEmpty(store.getState().userData.userData)) {
                        return (
                          <Redirect
                            to={{
                              pathname: "/auth",
                              state: { from: "/checkout" },
                            }}
                          />
                        );
                      } else {
                        return <CheckOutRoute />;
                      }
                    }}
                  />

                  <Route
                    exact
                    strict
                    path={`${process.env.PUBLIC_URL}/orders/:id`}
                    component={OrderSuccess}
                  />

                  <Route
                    exact
                    strict
                    path={`${process.env.PUBLIC_URL}/auth`}
                    component={UserLoginRoute}
                  />
                  <Route
                    exact
                    strict
                    path={`${process.env.PUBLIC_URL}/signup`}
                    component={UserSignupRoute}
                  />

                  <Route
                    exact
                    strict
                    path={`${process.env.PUBLIC_URL}/orders`}
                    render={() => {
                      if (_.isEmpty(store.getState().userData.userData)) {
                        return (
                          <Redirect
                            to={{
                              pathname: "/auth",
                              state: { from: "/orders" },
                            }}
                          />
                        );
                      } else {
                        return <OrderRoute />;
                      }
                    }}
                  />
                </Layout>
              </Switch>
            </ScrollContext>
          </BrowserRouter>
        </IntlProvider>
      </Provider>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
