import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";

// import { getTotal, getCartProducts } from "../../../reducers";
import { addToCart, addToWishlist, addToCompare } from "../../actions";
import ProductListItem from "./ProductItem";

class ProductListing extends Component {
  constructor(props) {
    super(props);
    this.state = { limit: 5, hasMoreItems: true };
  }

  componentWillMount() {
    this.fetchMoreItems();
  }

  fetchMoreItems = () => {
    if (!_.isEmpty(this.props.products)) {
      if (this.state.limit >= this.props.products.length) {
        this.setState({ hasMoreItems: false });
        return;
      }
      // a fake async api call
      setTimeout(() => {
        this.setState({
          limit: this.state.limit + 5,
        });
      }, 3000);
    }
  };

  render() {
    const {
      products,
      addToCart,
      symbol,
      addToWishlist,
      addToCompare,
      searchKey,
    } = this.props;
    return (
      <div>
        <div className="product-wrapper-grid">
          <div className="container-fluid pl-0 pr-0">
            {!_.isEmpty(products) ? (
              <InfiniteScroll
                dataLength={this.state.limit}
                next={this.fetchMoreItems}
                hasMore={this.state.hasMoreItems}
                loader={<div className="loading-cls" />}
                endMessage={
                  <p className="seen-cls seen-it-cls">
                    <b>Yay! You have seen it all</b>
                  </p>
                }
              >
                <div className="row">
                  {products.slice(0, this.state.limit).map((product, index) => (
                    <div className="col-6 col-md-3 col-lg-3 p-1" key={index}>
                      <ProductListItem
                        searchKey={searchKey}
                        product={product}
                        symbol={symbol}
                        onAddToCompareClicked={() => addToCompare(product)}
                        onAddToWishlistClicked={() => addToWishlist(product)}
                        onAddToCartClicked={addToCart}
                        key={index}
                      />
                    </div>
                  ))}
                </div>
              </InfiniteScroll>
            ) : (
              <div className="row">
                <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                  <img
                    alt="search-empty"
                    src={`${
                      process.env.PUBLIC_URL
                    }/assets/images/empty-search.jpg`}
                    className="img-fluid mb-4"
                  />
                  <h3>
                    Sorry! Couldn't find the product you were looking For!!!{" "}
                  </h3>
                  <p>
                    Please check if you have misspelt something or try searching
                    with other words.
                  </p>
                  <Link
                    to={`${process.env.PUBLIC_URL}/`}
                    className="btn btn-solid mt-4"
                  >
                    continue shopping
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  symbol: state.data.symbol,
});

export default connect(
  mapStateToProps,
  { addToCart, addToWishlist, addToCompare }
)(ProductListing);
