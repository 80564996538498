import React from "react";
import "./style.scss";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getSelectedAddress } from "../../actions/user";

const AddressCard = ({
  address,
  selectedAddress,
  onClick,
  history,
  onEdit,
}) => {
  return (
    <div className="address-card-component">
      <div className="card-header-section">
        <h5 className="title">
          {address.firstName} {address.lastName}
        </h5>
        <button
          style={{ border: "none", marginLeft: "5px", width: "10%" }}
          onClick={onEdit}
        >
          <i class="fa fa-pencil" aria-hidden="true" />
        </button>
        <button
          style={{ border: "none", marginLeft: "5px", width: "10%" }}
          onClick={onClick}
        >
          <i class="fa fa-trash-o" aria-hidden="true" />
        </button>
      </div>

      <p className="addrress">
        {address.address1},{address.address2},{address.city},{address.state},
        {address.country}
      </p>
      <button
        onClick={() => {
          selectedAddress(address);
          history.push("/checkout");
        }}
        class="btn btn-solid p-2"
      >
        Deliver to this address
      </button>
    </div>
  );
};

const mapToDispatchProps = (dispatch) => {
  return {
    selectedAddress: (address) => dispatch(getSelectedAddress(address)),
  };
};

export default connect(
  null,
  mapToDispatchProps
)(withRouter(AddressCard));
