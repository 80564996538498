import React, { Component } from "react";
import "./style.scss";
import { userLogin } from "../../actions/user";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
import SimpleReactValidator from "simple-react-validator";
import { LoaderComponent } from "../../components/Ui/loader";
import _ from "lodash";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      phone: "",
      password: "",
      checked: true,
    };

    this.validator = new SimpleReactValidator();
  }

  setStateFromInput = (event) => {
    var obj = {};
    obj[event.target.name] = event.target.value;
    this.setState(obj);
  };

  userLogin = () => {
    if (this.validator.allValid()) {
      if (this.state.checked) {
        this.setState({ isLoading: true });
        this.props
          .userLogin(this.state.phone, this.state.password)
          .then((response) => {
            if (!response["data"]["status"]) {
              if (response["data"]["message"] === "User doesnt exist") {
                toast.error("Sorry user details does not exist");
              } else if (
                response["data"]["message"] === "Invalid password" ||
                response["data"]["message"] === "Wrong password"
              ) {
                toast.error("Please enter your valid password and try again..");
              } else if (
                response["data"]["message"] === "Invalid email/phoneNumber"
              ) {
                toast.error("Please enter your mobile number and try again..");
              }
              this.setState({ isLoading: false });
            } else {
              if (!_.isNil(this.props.redirectPath)) {
                this.props.history.replace(this.props.redirectPath);
              } else {
                this.props.history.replace("/");
              }
              this.setState({ isLoading: false });
            }
          })
          .catch((error) => {
            this.setState({ isLoading: false });
            toast.error("Something went to wrong please try again...");
          });
      } else {
        toast.warn(
          "Please read and agree to the Terms and Conditions and privay policy by checking the box"
        );
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    return (
      <div>
        {/*Login section*/}
        <section className="login-page section-b-space">
          <div className="container">
            <div className="login-container">
              <h3>Login</h3>
              <div className="theme-card">
                {this.state.isLoading ? (
                  <LoaderComponent />
                ) : (
                  <form className="theme-form">
                    <div className="form-group p-0" style={{ width: "100%" }}>
                      <label htmlFor="phone">Mobile</label>
                      <input
                        type="tel"
                        className="form-control"
                        name="phone"
                        value={this.state.phone}
                        placeholder="Mobile"
                        onChange={this.setStateFromInput}
                      />
                      {this.validator.message(
                        "Mobile",
                        this.state.phone,
                        "required|phone"
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="password">Password</label>
                      <input
                        type="password"
                        className="form-control"
                        name="password"
                        value={this.state.password}
                        placeholder="Password"
                        onChange={this.setStateFromInput}
                      />
                      {this.validator.message(
                        "password",
                        this.state.password,
                        "required"
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <button
                        type="button"
                        onClick={this.userLogin}
                        className="btn btn-solid"
                      >
                        Login
                      </button>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "20px",
                      }}
                    >
                      <p>
                        Don't have an account ?{" "}
                        <Link to="/signup">Register Here</Link>
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "5px",
                      }}
                    >
                      <p>
                       
                        <Link to="/reset-password">Forgot Password ?</Link>
                      </p>
                    </div>
                    <div
                      style={{
                        // display: "flex",
                        // alignItems: "center",
                        // justifyContent: "center",
                        marginTop: "20px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="term_condition"
                        name="term_condition"
                        checked={this.state.checked}
                        onChange={(e) => {
                          const { checked } = e.target;
                          this.setState({ checked });
                        }}
                      />
                      <a
                        className="ml-2"
                        style={{ textDecoration: "underline" }}
                        href="https://psgmyperfectfit.co.in/terms-conditions"
                      >
                        I accept the Terms of Service
                      </a>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapToStateProps = (state, ownProps) => {
  let path = "/";
  if (!_.isEmpty(ownProps.location.state)) {
    if (!_.isNil(ownProps.location.state.from)) {
      path = ownProps.location.state.from;
    }
  }
  return {
    redirectPath: path,
  };
};

const mapToDispatchProps = (dispatch) => {
  return {
    userLogin: (email, password) => dispatch(userLogin(email, password)),
  };
};

export default connect(
  mapToStateProps,
  mapToDispatchProps
)(Login);
