import React from "react";
import "./style.scss";

export const LoaderComponent = () => {
  return (
    <div className="loader-component">
      <div className="row">
        <div className="col-sm-12 text-center section-b-space mt-5 no-found">
          <img src={`${process.env.PUBLIC_URL}/assets/images/loader.gif`} />
        </div>
      </div>
    </div>
  );
};
