import {
  USER_LOGIN_START,
  GET_USER_DETAILS,
  GET_STYLIST_INFO,
  GET_USER_ADDRESSES,
  SELECTED_ADDRESS,
  POST_USER_ADDRESS,
  GET_USER_ORDERS,
  USER_LOGOUT,
} from "../constants/ActionTypes";

const initialState = {
  userData: {},
  stylistData: {},
  userAddresses: [],
  deliveryAddress: {},
  orders: [],
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN_START:
      return { ...state };
    case GET_USER_DETAILS:
      return { ...state, userData: action.userData };
    case GET_STYLIST_INFO:
      return { ...state, stylistData: action.stylistData };
    case GET_USER_ADDRESSES:
      return { ...state, userAddresses: action.addresses };
    case GET_USER_ORDERS:
      return { ...state, orders: action.orders };
    case SELECTED_ADDRESS:
      return { ...state, deliveryAddress: action.deliveryAddress };
    case POST_USER_ADDRESS:
      return { ...state };
    case USER_LOGOUT:
      localStorage.clear();
      return {
        ...state,
        userData: {},
        userAddresses: [],
        stylistData: {},
        deliveryAddress: {},
        orders: [],
      };
    default:
      return state;
  }
};

export default userReducer;
