import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./style.scss";
//import Modal from 'react-responsive-modal';

class CategoriesCard extends Component {
  render() {
    const { category } = this.props;
    
    return (
      <Link to={category.path}>
        <div className="">
          <div className="img-wrapper categories-card" style={{backgroundImage:`url(${category.image})`}}>
            {/* <div className="front">
              <img src={category.image} className="img-fluid" alt="" />
            </div> */}
          </div>
          <div className="product-detail">
            <div className="mt-3 p-1">
              <p className="text-center">{category.title}</p>
            </div>
          </div>
        </div>
      </Link>
    );
  }
}

export default CategoriesCard;
