import React from "react";
import "./style.scss";
import { psgurl } from "../../api/axios";
import { Link } from "react-router-dom";

// import { shopLink } from "../../../data/config";

const MobileFooter = (props) => {
  return (
    <div className="mobile-icon-footer">
      <div className="footer-section">
        <a href={`${psgurl}/main/cover`}>
          <div className="footer-item">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/home.svg`}
              width="22px"
              alt="home_icon"
            />
            <label>Home</label>
          </div>
        </a>
        <a href={`${psgurl}/main/mystyling`}>
          <div className="footer-item">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/shirt.svg`}
              width="22px"
              alt="home_icon"
            />
            <label>Styling</label>
          </div>
        </a>
        <a href={`${psgurl}/main/grooming`}>
          <div className="footer-item">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/hairdresser.svg`}
              width="22px"
              alt="home_icon"
            />
            <label>Grooming</label>
          </div>
        </a>

        <Link to={`${process.env.PUBLIC_URL}/`}>
          <div className="footer-item">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/shopping-cart.svg`}
              width="22px"
              alt="home_icon"
            />
            <label>Shop</label>
          </div>
        </Link>
        <a href={`${psgurl}/main/wardrobe`}>
          <div className="footer-item">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/hanger.svg`}
              width="22px"
              alt="home_icon"
            />
            <label>Wardrobe</label>
          </div>
        </a>
      </div>
    </div>
  );
};

export default MobileFooter;
