// Fetch All Products

export const FETCH_ALLPRODUCTS_BEGIN = "FETCH_ALLPRODUCTS_BEGIN";
export const RECEIVE_ALLPRODUCTS = "RECEIVE_ALLPRODUCTS";

// Get Products
export const FETCH_PRODUCTS_BEGIN = "FETCH_PRODUCTS_BEGIN";
export const RECEIVE_PRODUCTS = "RECEIVE_PRODUCTS";
export const FETCH_SINGLE_PRODUCT = "FETCH_SINGLE_PRODUCT";

// FILTERS
export const FILTER_BRAND = "FILTER_BRAND";
export const FILTER_COLOR = "FILTER_COLOR";
export const FILTER_PRICE = "FILTER_PRICE";
export const SORT_BY = "SORT_BY";

export const CHANGE_CURRENCY = "CHANGE_CURRENCY";

// Cart
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const INCREMENT_QTY = "INCREMENT_QTY";
export const DECREMENT_QTY = "DECREMENT_QTY";

// WishList
export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST";
export const REMOVE_FROM_WISHLIST = "REMOVE_FROM_WISHLIST";

// Compare
export const ADD_TO_COMPARE = "ADD_TO_COMPARE";
export const REMOVE_FROM_COMPARE = "REMOVE_FROM_COMPARE";

// CheckOut Process
export const CHECKOUT_REQUEST = "CHECKOUT_REQUEST";
export const CHECKOUT_SUCCESS = "CHECKOUT_SUCCESS";
export const CHECKOUT_FAILURE = "CHECKOUT_FAILURE";

// Get User

export const REQ_USERINFO = "REQ_USERINFO";
export const GET_USERINFO = "GET_USERINFO";

// GET_PRODUCTS

export const GET_CUSTOM_SHIRTS = "GET_CUSTOM_SHIRTS";
export const GET_SHOES = "GET_SHOES";
export const GET_TROUSERS = "GET_TROUSERS";
export const GET_SUITS = "GET_SUITS";
export const GET_REC_SHOES = "GET_REC_SHOES";
export const GET_CHINOS = "GET_CHINOS";
export const GET_KURTHAS = "GET_KURTHAS";
export const GET_INDO_WESTREN = "GET_INDO_WESTREN";
export const GET_SADRIES = "GET_SADRIES";
export const GET_BLAZERS = "GET_BLAZERS";
export const GET_REC_SHIRTS = "GET_REC_SHIRTS";
export const GET_REC_TROUSERS = "GET_REC_TROUSERS";

// Master Data

export const REQ_MASTER_ATTR = "REQ_MASTER_ATTR";
export const GET_MASTER_ATTR = "GET_MASTER_ATTR";

// User Actions

export const USER_LOGIN_START = "USER_LOGIN_START";
export const USER_REGISTRATION_START = "USER_REGISTRATION_START";
export const USER_LOGOUT = "USER_LOGOUT";

export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const GET_STYLIST_INFO = "GET_STYLIST_INFO";
export const GET_USER_ADDRESSES = "GET_USER_ADDRESSES";
export const SELECTED_ADDRESS = "SELECTED_ADDRESS";
export const POST_USER_ADDRESS = "POST_USER_ADDRESS";
export const DELETE_USER_ADDRESS = "DELETE_USER_ADDRESS";
export const GET_USER_ORDERS = "GET_USER_ORDERS,";
