import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import "./index.scss";
import { connect } from "react-redux";

// import custom Components

import DetailsWithPrice from "../../components/Ui/detailPrice";
import DetailsTopTabs from "../../components/Ui/detailsTopTabs";
import TopServiceBanner from "../../components/Ui/topServiceBanner";
import { addToCart, addToCartUnsafe, addToWishlist } from "../../actions";
import ImageZoom from "../../components/Ui/ImageZoom";
import SmallImages from "../../components/Ui/SmallImages";
import SimilarProducts from "../../components/Ui/similar_products";
import _ from "lodash";

class ProductDetails extends Component {
  constructor() {
    super();
    this.state = {
      open: false,
      nav1: null,
      nav2: null,
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  filterClick() {
    document.getElementById("filter").style.left = "-15px";
  }
  backClick() {
    document.getElementById("filter").style.left = "-365px";
  }

  render() {
    const {
      symbol,
      item,
      addToCart,
      addToCartUnsafe,
      addToWishlist,
      similarProducts,
      lookbook,
      searchKey,
      similarSearch,
    } = this.props;
    var products = {
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      fade: true,
    };
    var productsnav = {
      slidesToShow: 3,
      swipeToSlide: true,
      arrows: false,
      dots: false,
      focusOnSelect: true,
    };

    return (
      <div>
        <Helmet>
          {_.isEmpty(item) ? (
            <title>MPF Fashions</title>
          ) : (
            <title>MPF Fashions | {item.name}</title>
          )}
          <script
            src={`${process.env.PUBLIC_URL}/assets/revchat.js`}
            type="text/javascript"
          />
        </Helmet>

        {/* <Breadcrumb parent={"Product"} title={item.name} /> */}

        {!_.isEmpty(item) ? (
          <React.Fragment>
            <div className="collection-wrapper">
              {item.producttype[0]["name"] === "Custom Made" && (
                <TopServiceBanner />
              )}

              <div className="container">
                <div className="row">
                  <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="">
                      <div className="row">
                        <div className="col-lg-6 product-thumbnail">
                          <Slider
                            {...products}
                            asNavFor={this.state.nav2}
                            ref={(slider) => (this.slider1 = slider)}
                            className="product-slick"
                          >
                            {item.variants
                              ? item.variants.map((vari, index) => (
                                  <div key={index}>
                                    <ImageZoom image={vari.images} />
                                  </div>
                                ))
                              : item.images.map((vari, index) => (
                                  <div key={index}>
                                    <ImageZoom image={vari} />
                                  </div>
                                ))}
                          </Slider>
                          <SmallImages
                            item={item}
                            settings={productsnav}
                            navOne={this.state.nav1}
                          />
                        </div>
                        <DetailsWithPrice
                          symbol={symbol}
                          item={item}
                          navOne={this.state.nav1}
                          addToCartClicked={addToCart}
                          BuynowClicked={addToCartUnsafe}
                          addToWishlistClicked={addToWishlist}
                        >
                          <DetailsTopTabs item={item} />
                        </DetailsWithPrice>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mt-4">
                    <SimilarProducts
                      products={similarProducts}
                      title="Similar products"
                      type={"women"}
                      searchKey={similarSearch}
                    />
                  </div>
                </div>
                {!_.isEmpty(lookbook) && (
                  <div className="row">
                    <div className="col-12 mt-4">
                      <SimilarProducts
                        products={lookbook}
                        title="Complete the look"
                        type={"women"}
                        searchKey={searchKey}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </React.Fragment>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let productId = ownProps.match.params.id;
  let item = {};
  let lookBook = [];
  let similarProducts = [];
  let similarSearch = "";
  let searchKey = "";
  if (ownProps.location.search === "?search=all") {
    item = state.data.allProducts.find((el) => el._id === productId);
    similarProducts = state.data.allProducts;
    similarSearch = "all";
  } else if (ownProps.location.search === "?search=shirts") {
    item = state.data.customShirts.find((el) => el._id === productId);
    lookBook = state.data.customTrousers;
    similarProducts = state.data.customShirts;
    searchKey = "trousers";
    similarSearch = "shirts";
  } else if (ownProps.location.search === "?search=shoes") {
    item = state.data.shoes.find((el) => el._id === productId);
    similarProducts = state.data.shoes;
    similarSearch = "shoes";
    lookBook = state.data.customTrousers;
    searchKey = "trousers";
  } else if (ownProps.location.search === "?search=suits") {
    item = state.data.customSuits.find((el) => el._id === productId);
    similarProducts = state.data.customSuits;
    similarSearch = "suits";
    lookBook = state.data.customShirts;
    searchKey = "shirts";
  } else if (ownProps.location.search === "?search=trousers") {
    item = state.data.customTrousers.find((el) => el._id === productId);
    similarProducts = state.data.customTrousers;
    lookBook = state.data.customShirts;
    searchKey = "shirts";
    similarSearch = "trousers";
  } else if (ownProps.location.search === "?search=chinos") {
    item = state.data.customChinos.find((el) => el._id === productId);
    similarProducts = state.data.customChinos;
    lookBook = state.data.customShirts;
    searchKey = "shirts";
    similarSearch = "chinos";
  } else if (ownProps.location.search === "?search=kurthas") {
    item = state.data.customKurthas.find((el) => el._id === productId);
    similarProducts = state.data.customKurthas;
    similarSearch = "kurthas";
  } else if (ownProps.location.search === "?search=indo-westren") {
    item = state.data.customIndowestren.find((el) => el._id === productId);
    similarProducts = state.data.customIndowestren;
    similarSearch = "indo-westren";
  } else if (ownProps.location.search === "?search=sadri") {
    item = state.data.customSadri.find((el) => el._id === productId);
    similarProducts = state.data.customSadri;
    similarSearch = "sadri";
  } else if (ownProps.location.search === "?search=blazers") {
    item=state.data.customBlazers.find((el)=>el._id===productId);
    similarProducts=state.data.customBlazers;
    similarSearch="blazers"
  } else {
    item = {};
  }
  return {
    item: item,
    symbol: state.data.symbol,
    lookbook: lookBook,
    similarProducts: similarProducts,
    searchKey: searchKey,
    similarSearch: similarSearch,
  };
};

export default connect(
  mapStateToProps,
  {
    addToCart,
    addToCartUnsafe,
    addToWishlist,
  }
)(ProductDetails);
