import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getUserAddresses,
  postNewAddress,
  deleteAddress,
  updateAddress,
} from "../../actions/user";
import { LoaderComponent } from "../../components/Ui/loader";
import { EmptyDataComponent } from "../../components/Ui/emptydata";
import _ from "lodash";
import AddressCard from "../../components/Ui/addressCard";
import "./style.scss";
import Modal from "react-responsive-modal";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

class Address extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: _.isEmpty(this.props.userData)
        ? ""
        : this.props.userData["firstName"],
      lastName: _.isEmpty(this.props.userData)
        ? ""
        : this.props.userData["lastName"],
      phone: _.isEmpty(this.props.userData) ? "" : this.props.userData["phone"],
      email: _.isEmpty(this.props.userData) ? "" : this.props.userData["email"],
      country: "",
      address1: "",
      address2: "",
      landmark: "",
      city: "",
      state: "",
      postalCode: "",
      isLoading: false,
      openModel: false,
      isEditForm: false,
      itemId: null,
    };
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.props
      .getUserAddresses(this.props.userData["_id"])
      .then((response) => {
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  }

  setStateFromInput = (event) => {
    var obj = {};
    obj[event.target.name] = event.target.value;
    this.setState(obj);
  };

  handlePostNewAddress = () => {
    if (this.validator.allValid()) {
      let payload = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        phone: this.state.phone,
        email: this.state.email,
        country: this.state.country,
        address1: this.state.address1,
        address2: this.state.address2,
        landmark: this.state.lastName,
        city: this.state.city,
        state: this.state.state,
        postalCode: this.state.postalCode,
      };
      this.setState({ isLoading: true });
      this.props
        .postNewAddress(this.props.userData["_id"], payload)
        .then((response) => {
          return this.props.getUserAddresses(this.props.userData["_id"]);
        })
        .then((response) => {
          this.setState({ isLoading: false, openModel: false });
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          toast.error("Something went to wrong  please try again");
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleEditForm = (selectAddress) => {
    this.setState({
      openModel: true,
      firstName: selectAddress.firstName,
      lastName: selectAddress.lastName,
      phone: selectAddress.phone,
      email: selectAddress.email,
      country: selectAddress.country,
      address1: selectAddress.address1,
      address2: selectAddress.address2,
      landmark: selectAddress.lastName,
      city: selectAddress.city,
      state: selectAddress.state,
      postalCode: selectAddress.postalCode,
      isEditForm: true,
      itemId: selectAddress["_id"],
    });
  };

  handleUpdateAddress = () => {
    if (this.validator.allValid()) {
      let payload = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        phone: this.state.phone,
        email: this.state.email,
        country: this.state.country,
        address1: this.state.address1,
        address2: this.state.address2,
        landmark: this.state.lastName,
        city: this.state.city,
        state: this.state.state,
        postalCode: this.state.postalCode,
      };
      this.setState({ isLoading: true });
      this.props
        .updateAddress(this.props.userData["_id"], this.state.itemId, payload)
        .then((response) => {
          return this.props.getUserAddresses(this.props.userData["_id"]);
        })
        .then((response) => {
          this.setState({
            isLoading: false,
            openModel: false,
            isEditForm: false,
          });
          toast.success("Delivery address Updated successfully");
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          toast.error("Something went to wrong  please try again");
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleDeleteAddress = (userId, itemId) => {
    this.props
      .deleteAddress(userId, itemId)
      .then((response) => {
        this.props.getUserAddresses(userId);
      })
      .catch((error) => {
        toast.error("Something went to wrong please try again");
      });
  };

  render() {
    const { isLoading, openModel } = this.state;
    const { addresses } = this.props;
    return (
      <div>
        <Helmet>
          <title>MPF Fashions | My Addresses</title>
          <script
            src={`${process.env.PUBLIC_URL}/assets/revchat.js`}
            type="text/javascript"
          />

          {/* <meta
            name="description"
            content="Multikart – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Multikart Bootstrap 4 Template will help you run multiple businesses."
          /> */}
        </Helmet>
        <section className="section-b-space">
          <div className="collection-wrapper">
            {isLoading ? (
              <LoaderComponent />
            ) : (
              <React.Fragment>
                {_.isEmpty(addresses) ? (
                  <EmptyDataComponent
                    onClick={() => this.setState({ openModel: true })}
                    data={{
                      path: "/",
                      title: "No addresses found !",
                      subTitle: "",
                      btnName: "Add address",
                    }}
                  />
                ) : (
                  <div className="container">
                    <div className="row">
                      <div className="col-12 col-lg-8 col-md-8 col-sm-8">
                        <h3 style={{ color: "black" }}>
                          Select a delivery address
                        </h3>
                      </div>
                      <div className="col-12 col-lg-4 col-md-4 col-sm-4 btn-align">
                        <button
                          onClick={() => this.setState({ openModel: true })}
                          class="btn btn-solid p-2"
                        >
                          Add delivery address
                        </button>
                      </div>
                    </div>

                    <hr />
                    <div className="row">
                      {addresses.map((address) => (
                        <div className="col-lg-3 col-md-3 col-sm-2 col-12 mt-4">
                          <AddressCard
                            address={address}
                            onEdit={() => this.handleEditForm(address)}
                            onClick={() =>
                              this.handleDeleteAddress(
                                this.props.userData["_id"],
                                address._id
                              )
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
          <Modal
            open={openModel}
            onClose={() =>
              this.setState({
                openModel: false,
                isLoading: false,
                isEditForm: false,
                itemId: null,
              })
            }
            center
          >
            <div
              className="modal-dialog modal-lg modal-dialog-centered p-0"
              role="document"
            >
              <div className="modal-content quick-view-modal">
                <div className="modal-body">
                  <div className="checkout-page">
                    <div className="checkout-form">
                      <form>
                        <div className="checkout row">
                          {isLoading ? (
                            <div style={{ width: "250px", height: "250px" }}>
                              <LoaderComponent />
                            </div>
                          ) : (
                            <div className="col-12">
                              <div className="checkout-title">
                                <h3>Enter Delivery Address</h3>
                              </div>
                              <div className="row check-out">
                                <div className="form-group col-md-6 col-sm-6 col-6 p-2 mb-1">
                                  <div className="field-label">First Name</div>
                                  <input
                                    type="text"
                                    name="firstName"
                                    value={this.state.firstName}
                                    onChange={this.setStateFromInput}
                                  />
                                  {this.validator.message(
                                    "firstName",
                                    this.state.firstName,
                                    "required|min:3|max:100"
                                  )}
                                </div>
                                <div className="form-group col-md-6 col-sm-6 col-6 p-2 mb-1">
                                  <div className="field-label">Last Name</div>
                                  <input
                                    type="text"
                                    name="lastName"
                                    value={this.state.lastName}
                                    onChange={this.setStateFromInput}
                                  />
                                  {this.validator.message(
                                    "lastName",
                                    this.state.lastName,
                                    "required|min:3|max:100"
                                  )}
                                </div>
                                <div className="form-group col-md-6 col-sm-6 col-6 p-2 mb-1">
                                  <div className="field-label">Phone</div>
                                  <input
                                    type="text"
                                    name="phone"
                                    value={this.state.phone}
                                    onChange={this.setStateFromInput}
                                  />
                                  {this.validator.message(
                                    "phone",
                                    this.state.phone,
                                    "required|phone"
                                  )}
                                </div>
                                <div className="form-group col-md-6 col-sm-6 col-6 p-2 mb-1">
                                  <div className="field-label">
                                    Email Address
                                  </div>
                                  <input
                                    type="text"
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.setStateFromInput}
                                  />
                                  {this.validator.message(
                                    "email",
                                    this.state.email,
                                    "required|email"
                                  )}
                                </div>

                                <div className="form-group col-md-6 col-sm-12 col-xs-12 p-2 mb-1">
                                  <div className="field-label">Address1</div>
                                  <input
                                    type="text"
                                    name="address1"
                                    value={this.state.address1}
                                    onChange={this.setStateFromInput}
                                    placeholder=""
                                  />
                                  {this.validator.message(
                                    "address1",
                                    this.state.address1,
                                    "required"
                                  )}
                                </div>
                                <div className="form-group col-md-6 col-sm-12 col-xs-12 p-2 mb-1">
                                  <div className="field-label">
                                    Address2 (Optional)
                                  </div>
                                  <input
                                    type="text"
                                    name="address2"
                                    value={this.state.address2}
                                    onChange={this.setStateFromInput}
                                    placeholder=""
                                  />
                                  {/* {this.validator.message(
                                  "address2",
                                  this.state.address2,
                                  "required"
                                )} */}
                                </div>
                                <div className="form-group col-12 p-2 mb-1">
                                  <div className="field-label">
                                    Land Mark (Optional)
                                  </div>
                                  <input
                                    type="text"
                                    name="landmark"
                                    value={this.state.landmark}
                                    onChange={this.setStateFromInput}
                                    placeholder=""
                                  />
                                  {/* {this.validator.message(
                                  "landmark",
                                  this.state.landmark,
                                  ""
                                )} */}
                                </div>
                                <div className="form-group col-md-3 col-sm-6 col-6 p-2 mb-1">
                                  <div className="field-label">Town/City</div>
                                  <input
                                    type="text"
                                    name="city"
                                    value={this.state.city}
                                    onChange={this.setStateFromInput}
                                  />
                                  {this.validator.message(
                                    "city",
                                    this.state.city,
                                    "required|min:3|max:50"
                                  )}
                                </div>
                                <div className="form-group col-md-3 col-sm-6 col-6  p-2 mb-1">
                                  <div className="field-label">State</div>
                                  <input
                                    type="text"
                                    name="state"
                                    value={this.state.state}
                                    onChange={this.setStateFromInput}
                                  />
                                  {this.validator.message(
                                    "state",
                                    this.state.state,
                                    "required"
                                  )}
                                </div>
                                <div className="form-group col-md-3 col-sm-6 col-6  p-2 mb-1">
                                  <div className="field-label">Country</div>
                                  <input
                                    type="text"
                                    name="country"
                                    value={this.state.country}
                                    onChange={this.setStateFromInput}
                                  />
                                  {this.validator.message(
                                    "country",
                                    this.state.country,
                                    "required"
                                  )}
                                </div>
                                <div className="form-group col-md-3 col-sm-6 col-6  p-2 mb0-1">
                                  <div className="field-label">Postal Code</div>
                                  <input
                                    type="text"
                                    name="postalCode"
                                    value={this.state.postalCode}
                                    onChange={this.setStateFromInput}
                                  />
                                  {this.validator.message(
                                    "postalCode",
                                    this.state.postalCode,
                                    "required"
                                  )}
                                </div>
                                <div className="form-group col-12   p-2 mb-1">
                                  <button
                                    type="button"
                                    onClick={(e) => {
                                      if (this.state.isEditForm) {
                                        this.handleUpdateAddress();
                                      } else {
                                        this.handlePostNewAddress(e);
                                      }
                                    }}
                                    className="btn btn-solid p-2"
                                  >
                                    {this.state.isEditForm
                                      ? "Update Address"
                                      : "Add Address"}
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    addresses: state.userData.userAddresses,
    userData: state.userData.userData,
  };
};

const mapToDispatchProps = (dispatch) => {
  return {
    getUserAddresses: (userId) => dispatch(getUserAddresses(userId)),
    postNewAddress: (userId, payload) =>
      dispatch(postNewAddress(userId, payload)),
    deleteAddress: (userId, itemId) => dispatch(deleteAddress(userId, itemId)),
    updateAddress: (userId, itemId, playload) =>
      dispatch(updateAddress(userId, itemId, playload)),
  };
};

export default connect(
  mapStateToProps,
  mapToDispatchProps
)(Address);
