import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Breadcrumb from "../../components/Ui/breadcrumb";
import ProductListing from "../../components/Ui/ProductList";
import { LoaderComponent } from "../../components/Ui/loader";
import { getCustomBlazers } from "../../actions/products";
import { getUserDetails } from "../../actions/user";
import { connect } from "react-redux";
// import CategorySlideComponent from "../../components/Ui/categorySlide";
import TwoTabs from "../../components/Ui/two_tabs";

import _ from "lodash";

class CustomBlazerRoute extends Component {
  state = {
    layoutColumns: 3,
    isLoading: false,
  };

  LayoutViewClicked(colums) {
    this.setState({
      layoutColumns: colums,
    });
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.props
      .getCustomBlazers()
      .then((response) => {
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
    if (!_.isNull(this.props.userId)) {
      this.props.getUserDetails(this.props.userId);
    }
  }

  render() {
    const { customBlazers } = this.props;
    return (
      <div>
        {/*SEO Support*/}
        <Helmet>
          <title>MPF Fashions | Custom Blazers</title>
          <script src={`${process.env.PUBLIC_URL}/assets/revchat.js`} type="text/javascript" />

          {/* <meta
            name="description"
            content="Multikart – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Multikart Bootstrap 4 Template will help you run multiple businesses."
          /> */}
        </Helmet>
        {/*SEO Support End */}

        <Breadcrumb title={"Custom Blazers"} />

        <section className="section-b-space">
          <div className="collection-wrapper">
            <div className="container">
              {/* <CategorySlideComponent categories={shirts_category} /> */}
              <div className="row">
                <div className="collection-content col-12">
                  <TwoTabs/>
                  <div className="page-main-content ">
                    <div className="">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="collection-product-wrapper">
                            {this.state.isLoading ? (
                              <LoaderComponent />
                            ) : (
                              <ProductListing
                                searchKey="blazers"
                                products={customBlazers}
                                colSize={this.state.layoutColumns}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let userId = null;
  if (!_.isEmpty(ownProps.match.params)) {
    userId = ownProps.match.params.id;
  }

  return {
    customBlazers: state.data.customBlazers,
    symbol: state.data.symbol,
    userId: userId,
  };
};

const mapToDispatch = (dispatch) => {
  return {
    getCustomBlazers: () => dispatch(getCustomBlazers()),
    getUserDetails: (userId) => dispatch(getUserDetails(userId)),
  };
};

export default connect(
  mapStateToProps,
  mapToDispatch
)(CustomBlazerRoute);
