import React from "react";
import { connect } from "react-redux";
import "./style.scss";
import _ from "lodash";
import { Link } from "react-router-dom";

const OrderCard = ({ symbol, orders }) => {
  return (
    <React.Fragment>
      {orders.map((order) => (
        <Link
          to={`${process.env.PUBLIC_URL}/orders/${order._id}`}
          style={{ color: "black" }}
        >
          <div className="order-component mb-4">
            <div className="order-header">
              <h4>
                Date : {order.orderDateTime.month}-{order.orderDateTime.day}-{" "}
                {order.orderDateTime.year}
              </h4>
              <h6>Order Id : {order.orderId}</h6>
            </div>
            <hr />
            <div>
              <tabel>
                <th>
                  <b>Items({order.items.length})</b>
                </th>
              </tabel>
            </div>
            <hr />
            {order.items.map((item) => (
              <div className="order-item-card mb-4">
                <div className="mr-3">
                  {item.images.length > 0 ? (
                    <img
                      alt={item.name}
                      src={item.images[0]}
                      className="img-fluid img-section"
                    />
                  ) : (
                    <img
                      alt={item.name}
                      src={`${process.env.PUBLIC_URL}/assets/images/20.jpg`}
                      className="img-fluid img-section"
                    />
                  )}
                </div>
                <div>
                  <h5
                    style={{
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {item.name}
                  </h5>

                  <h6
                    className="m-0"
                    style={{
                      color: "black",
                    }}
                  >
                    <b>
                      Price :{" "}
                      <span
                        style={{
                          display: "inline",
                          color: "#e17055",
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        {symbol} {item.discPrice} /-
                      </span>
                    </b>
                  </h6>
                  <span>
                    Qty : <b>{item.qty}</b>
                  </span>
                  {!_.isEmpty(item.size) && (
                    <span>
                      Size : <b>{item.size}</b>
                    </span>
                  )}
                </div>
                <hr />
              </div>
            ))}
          </div>
        </Link>
      ))}
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    symbol: state.data.symbol,
  };
};

export default connect(
  mapStateToProps,
  null
)(OrderCard);
