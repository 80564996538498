import React, { Component } from "react";
import "./styles.scss";
import { userLogin } from "../../actions/user";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import { Link } from "react-router-dom";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
import SimpleReactValidator from "simple-react-validator";
import { LoaderComponent } from "../../components/Ui/loader";
import _ from "lodash";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { instanceOne } from "../../api/axios";

class ForgotPasword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      phone: "",
      email: "",
      password: "",
      checked: true,
      step: 1,
    };

    this.validator = new SimpleReactValidator();
  }

  setStateFromInput = (event) => {
    var obj = {};
    obj[event.target.name] = event.target.value;
    this.setState(obj);
  };

  initPasswordReq = () => {
    let body = {
      phone: this.state.phone,
    };
    if (this.validator.allValid()) {
      this.setState({ isLoading: true });
      instanceOne
        .post("/user/initiateresetpassword", body)
        .then((response) => {
          if (response["data"]["status"]) {
            this.setState({
              isLoading: false,
              step: 2,
              email: response["data"]["details"]["email"],
            });
            toast.success("OTP Sent your registred email addess.");
          } else {
            this.setState({ isLoading: false });
            toast.error(
              "Mobile number does not exist please enter valid mobile number."
            );
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          toast.error("Something went to wrong please try again.");
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  validateOpt = () => {
    let payload = {
      phone: this.state.phone,
      otp: this.state.otp,
    };
    if (this.validator.allValid()) {
      this.setState({ isLoading: true });
      instanceOne
        .post("/user/checkotp", payload)
        .then((response) => {
          console.log(response);
          if (response["data"]["status"]) {
            this.setState({
              isLoading: false,
              step: 3,
            });
            toast.success('Successfully Verified OTP');
          } else {
            this.setState({ isLoading: false });
            toast.error("Please enter valid OTP.");
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          toast.error("Something went to wrong please try again.");
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  changePassword = () => {
    let payload = {
      phone: this.state.phone,
      otp: this.state.otp,
      new_password: this.state.password,
    };
    if (this.validator.allValid()) {
      this.setState({ isLoading: true });
      instanceOne
        .post("/user/resetpassword", payload)
        .then((response) => {
          console.log(response);
          if (response["data"]["status"]) {
            this.setState({
              isLoading: false,
              step: 1,
              phone: "",
              email: "",
              password: "",
              otp: "",
            });
            toast.success("New password updated successfully");
            this.props.history.replace("/");
          } else {
            this.setState({ isLoading: false });
            toast.error("Something went to wrong please try again.");
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          toast.error("Something went to wrong please try again.");
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    return (
      <div>
        {/*Login section*/}
        <section className="login-page section-b-space">
          <div className="container">
            <div className="login-container">
              <h3>Forgot your password ?</h3>
              <div className="theme-card">
                {this.state.isLoading ? (
                  <LoaderComponent />
                ) : (
                  <React.Fragment>
                    {this.state.step === 1 && (
                      <form className="theme-form">
                        <div
                          className="form-group p-0"
                          style={{ width: "100%" }}
                        >
                          <label htmlFor="phone">Mobile:</label>
                          <input
                            type="tel"
                            className="form-control"
                            name="phone"
                            value={this.state.phone}
                            placeholder="Enter your registered mobile number"
                            onChange={this.setStateFromInput}
                          />
                          {this.validator.message(
                            "phone",
                            this.state.phone,
                            "required|phone"
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <button
                            type="button"
                            onClick={this.initPasswordReq}
                            className="btn btn-solid"
                          >
                            Next
                          </button>
                        </div>
                      </form>
                    )}
                    {this.state.step === 2 && (
                      <form className="theme-form">
                        <h5>Please enter the OTP to verify your account</h5>
                        <h6 className="mb-5">
                          Note : OTP has been sent to this{" "}
                          <span style={{ color: "black" }}>
                            <b>{this.state.email}</b>
                          </span>{" "}
                          registred email address
                        </h6>
                        <div
                          className="form-group p-0"
                          style={{ width: "100%" }}
                        >
                          <label htmlFor="otp">OTP:</label>
                          <input
                            type="tel"
                            className="form-control"
                            name="otp"
                            value={this.state.otp}
                            placeholder="Enter OTP"
                            onChange={this.setStateFromInput}
                          />
                          {this.validator.message(
                            "otp",
                            this.state.otp,
                            "required"
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <button
                            type="button"
                            onClick={() => this.setState({ step: 1 })}
                            className="btn btn-solid"
                          >
                            Back
                          </button>
                          <button
                            type="button"
                            onClick={this.validateOpt}
                            className="btn btn-solid ml-4"
                          >
                            Next
                          </button>
                        </div>
                      </form>
                    )}
                    {this.state.step === 3 && (
                      <form className="theme-form">
                        {/* <h5>Please enter new password</h5> */}
                        <div
                          className="form-group p-0"
                          style={{ width: "100%" }}
                        >
                          <label htmlFor="password">New Password:</label>
                          <input
                            type="tel"
                            className="form-control"
                            name="password"
                            value={this.state.password}
                            placeholder="Enter new password"
                            onChange={this.setStateFromInput}
                          />
                          {this.validator.message(
                            "password",
                            this.state.password,
                            "required|min:8"
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <button
                            type="button"
                            onClick={() => this.setState({ step: 2 })}
                            className="btn btn-solid"
                          >
                            Back
                          </button>
                          <button
                            type="button"
                            onClick={this.changePassword}
                            className="btn btn-solid ml-4"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    )}

                    {/* <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginTop: "20px",
                        }}
                      >
                        <p>
                          Don't have an account ?{" "}
                          <Link to="/signup">Register Here</Link>
                        </p>
                      </div> */}
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapToStateProps = (state, ownProps) => {
  let path = "/";
  if (!_.isEmpty(ownProps.location.state)) {
    if (!_.isNil(ownProps.location.state.from)) {
      path = ownProps.location.state.from;
    }
  }
  return {
    redirectPath: path,
  };
};

const mapToDispatchProps = (dispatch) => {
  return {
    userLogin: (email, password) => dispatch(userLogin(email, password)),
  };
};

export default connect(
  mapToStateProps,
  mapToDispatchProps
)(withRouter(ForgotPasword));
