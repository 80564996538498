export const userAttr = {
  userName: "",
  customerId: "",
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  phone2: "",
  password: "mpf@1234",
  confirmPassword: "",
  otp: "",
  countryId: "",
  city: "",
  state: "",
  pincode: "",
  homeAddress: "",
  assignedTo: false,
  addedByAdmin: "",
  isForgotpasswordVerified_ThroughMobile: false,
  isForgotpasswordVerified: false,
  isEmailVerified: false,
  isMobileVerified: false,
  images: {
    profile: "",
    cover: "",
    other: [],
  },
  dob: {
    day: 0,
    month: 0,
    year: 0,
  },
  about: "",
  role: "",
  payment_type: "",
  package_name: "",
  package_amount: 0,
  isPaid: false,
  tags:['shopNowUser']
};
