import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.scss";
import { connect } from "react-redux";
import { gruopValues, dateCount } from "../../services";
import "./style.scss";
import _ from "lodash";
class DetailsTopTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productType: "",
      filterItems: [...this.props.item["tags"]],
      attributes: { ...this.props.attributes },
    };
  }

  render() {
    const { stylist } = this.props;
    return (
      <section className="tab-product m-0">
        <div className="row">
          <div className="col-sm-12 col-lg-12 details-top-tabs">
            <Tabs className="tab-content nav-material">
              <TabList className="nav nav-tabs nav-material">
                <Tab className="nav-item">
                  <span className="nav-link active">
                    <i className="icofont icofont-ui-home" />
                    Details
                  </span>
                  <div className="material-border" />
                </Tab>
                <Tab className="nav-item">
                  <span className="nav-link">
                    <i className="icofont icofont-man-in-glasses" />
                    Size
                  </span>
                  <div className="material-border" />
                </Tab>
                <Tab className="nav-item">
                  <span className="nav-link">
                    <i className="icofont icofont-contacts" />
                    Delivery
                  </span>
                  <div className="material-border" />
                </Tab>
              </TabList>
              <TabPanel className="tab-pane fade mt-4 show active">
                <p className="mt-4 p-0">
                  <h5 className="product-title">Product Specification</h5>
                  {this.props.item.producttype[0]["name"] === "Custom Made" && (
                    <table className="table table-striped mb-0">
                      <tbody>
                        <tr>
                          <th>Product Id:</th>
                          <td>
                            {this.props.item.prefix}
                            {this.props.item.pId}
                          </td>
                        </tr>
                        <tr>
                          <th>Brand:</th>
                          <td>{this.props.item.brand[0]["name"]}</td>
                        </tr>
                        <tr>
                          <th>Actual Color :</th>
                          <td>{this.props.item.colors[0]["color"]}</td>
                        </tr>
                        <tr>
                          <th>Fabric Type :</th>
                          <td>{this.props.item.fabricType}</td>
                        </tr>

                        {gruopValues(
                          this.state.filterItems,
                          this.state.attributes
                        ).map((item, index) => (
                          <tr key={index}>
                            <th>{item.name}:</th>
                            <td>{item.value}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                  {this.props.item.producttype[0]["name"] === "Ready Made" && (
                    <table className="table table-striped mb-0">
                      <tbody>
                        <tr>
                          <th>Product Id:</th>
                          <td>
                            {this.props.item.prefix}
                            {this.props.item.pId}
                          </td>
                        </tr>
                        <tr>
                          <th>Brand:</th>
                          <td>{this.props.item.name}</td>
                        </tr>
                        <tr>
                          <th>Actual Color :</th>
                          <td>{this.props.item.colors[0].color}</td>
                        </tr>

                        {gruopValues(
                          this.state.filterItems,
                          this.state.attributes
                        ).map((item, index) => (
                          <tr key={index}>
                            <th>{item.name}:</th>
                            <td>{item.value}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </p>
              </TabPanel>
              <TabPanel>
                {this.props.item.producttype[0]["name"] === "Custom Made" ? (
                  <React.Fragment>
                    <div>
                      Your outfit will be fully customized and made exclusively
                      for you based on your measurements profile. After you buy,
                      we have a very simple process for online measurements.
                      {_.isEmpty(stylist) ? (
                        ""
                      ) : (
                        <React.Fragment>
                          {!_.isEmpty(stylist["stylist"]) && (
                            <React.Fragment>
                              `Your personal stylist will contact you via email
                              within 24 hours of placing your order - or your
                              money back! You can simply measure yourself by
                              following 3 steps process or have a video call
                              with your personal stylist $
                              {stylist.stylist[0].name} for measurements.`
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      )}
                    </div>
                  </React.Fragment>
                ) : (
                  <p>
                    Go for your normal UK/INDIA size. In case you have any
                    further query relating to size, you can view the size chart
                    or contact your stylist for further assistance.{" "}
                  </p>
                )}
              </TabPanel>
              <TabPanel>
                <div className="mt-4 text-left">
                  <div className="deliverey-section">
                    <span>
                      <b>In India:</b>
                    </span>{" "}
                    Free shipping with India. Your item can be delivered in next{" "}
                    {this.props.item.deliveryDays}
                    days on or before {dateCount(this.props.item.deliveryDays)}.
                  </div>
                  <hr />
                  <div className="deliverey-section">
                    <span>
                      <b>Outside India</b>:
                    </span>{" "}
                    Our standard timelines vary from 3-4 weeks for orders
                    outside india. Additional Shipping charges are applicable
                    outside India.
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </section>
    );
  }
}

export const mapToStateProps = (state) => {
  return {
    attributes: state.data.masterAttributes,
    stylist: state.userData.stylistData,
  };
};

export default connect(
  mapToStateProps,
  null
)(DetailsTopTabs);
