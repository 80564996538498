import * as types from "../constants/ActionTypes";
import { instanceOne } from "../api/axios";
import _ from "lodash";

export const getAllProducts = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: types.FETCH_ALLPRODUCTS_BEGIN,
    });
    instanceOne
      .get("/product/random/all")
      .then((response) => {
        if (response["data"]["status"]) {
          dispatch({
            type: types.RECEIVE_ALLPRODUCTS,
            allProducts: response["data"]["details"],
          });
        } else {
          dispatch({
            type: types.RECEIVE_ALLPRODUCTS,
            allProducts: [],
          });
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getMasterAttributes = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: types.REQ_MASTER_ATTR,
    });
    instanceOne
      .get("masterproductattr/all/")
      .then((response) => {
        if (response["data"]["status"]) {
          dispatch({
            type: types.GET_MASTER_ATTR,
            masterAttrs: response["data"]["details"],
          });
        } else {
          dispatch({
            type: types.GET_MASTER_ATTR,
            masterAttrs: {},
          });
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getCustomeShirts = () => (dispatch, getStore) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: types.FETCH_PRODUCTS_BEGIN,
    });

    instanceOne
      .get("/product/shirts/all")
      .then((response) => {
        if (response["data"]["status"]) {
          dispatch({
            type: types.GET_CUSTOM_SHIRTS,
            customShirts: response["data"]["details"],
          });
          return instanceOne.get("/product/trousers/all");
        } else {
          dispatch({
            type: types.GET_CUSTOM_SHIRTS,
            customShirts: [],
          });
        }
        resolve(response);
      })
      .then((response) => {
        dispatch({
          type: types.GET_TROUSERS,
          customTrousers: response["data"]["details"],
        });
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getShoes = () => (dispatch, getStore) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: types.FETCH_PRODUCTS_BEGIN,
    });
    instanceOne
      .get("/product/shoes/all")
      .then((response) => {
        if (response["data"]["status"]) {
          dispatch({
            type: types.GET_SHOES,
            shoes: response["data"]["details"],
          });
          return instanceOne.get("/product/trousers/all");
        } else {
          dispatch({
            type: types.GET_SHOES,
            shoes: [],
          });
        }
        resolve(response);
      })
      .then((response) => {
        dispatch({
          type: types.GET_TROUSERS,
          customTrousers: response["data"]["details"],
        });
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getTrousers = () => (dispatch, getStore) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: types.FETCH_PRODUCTS_BEGIN,
    });

    instanceOne
      .get("/product/trousers/all")
      .then((response) => {
        if (response["data"]["status"]) {
          dispatch({
            type: types.GET_TROUSERS,
            customTrousers: response["data"]["details"],
          });
          return instanceOne.get("/product/shirts/all");
        } else {
          dispatch({
            type: types.GET_TROUSERS,
            customTrousers: [],
          });
        }
        resolve(response);
      })
      .then((response) => {
        dispatch({
          type: types.GET_CUSTOM_SHIRTS,
          customShirts: response["data"]["details"],
        });
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getSuits = () => (dispatch, getStore) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: types.FETCH_PRODUCTS_BEGIN,
    });

    instanceOne
      .get("/product/suits/all")
      .then((response) => {
        if (response["data"]["status"]) {
          dispatch({
            type: types.GET_SUITS,
            customSuits: response["data"]["details"],
          });
          return instanceOne.get("/product/shirts/all");
        } else {
          dispatch({
            type: types.GET_SUITS,
            customSuits: [],
          });
        }
        resolve(response);
      })
      .then((response) => {
        dispatch({
          type: types.GET_CUSTOM_SHIRTS,
          customShirts: response["data"]["details"],
        });
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getCustomBlazers = () => (dispatch, getStore) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: types.FETCH_PRODUCTS_BEGIN,
    });

    instanceOne.get("/product/blazer/all").then((response) => {
      if (response["data"]["status"]) {
        dispatch({
          type: types.GET_BLAZERS,
          customBlazers: response["data"]["details"],
        });
      } else {
        dispatch({
          type: types.GET_BLAZERS,
          customBlazers: [],
        });
      }
      resolve(response);
    });
  });
};

export const getChinos = () => (dispatch, getStore) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: types.FETCH_PRODUCTS_BEGIN,
    });

    instanceOne
      .get("product/chinos/all")
      .then((response) => {
        if (response["data"]["status"]) {
          dispatch({
            type: types.GET_CHINOS,
            customChinos: response["data"]["details"],
          });
          return instanceOne.get("product/shirts/all");
        } else {
          dispatch({
            type: types.GET_CHINOS,
            customChinos: [],
          });
        }
        resolve(response);
      })
      .then((response) => {
        dispatch({
          type: types.GET_CUSTOM_SHIRTS,
          customShirts: response["data"]["details"],
        });
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const getKurthas = () => (dispatch, getStore) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: types.FETCH_PRODUCTS_BEGIN,
    });

    instanceOne
      .get("product/kurta/all")
      .then((response) => {
        if (response["data"]["status"]) {
          dispatch({
            type: types.GET_KURTHAS,
            customKurthas: response["data"]["details"],
          });
        } else {
          dispatch({
            type: types.GET_KURTHAS,
            customKurthas: [],
          });
        }
        resolve(response);
      })

      .catch((error) => {
        reject(error);
      });
  });
};
export const getIndoWestren = () => (dispatch, getStore) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: types.FETCH_PRODUCTS_BEGIN,
    });

    instanceOne
      .get("product/indo_western/all")
      .then((response) => {
        if (response["data"]["status"]) {
          dispatch({
            type: types.GET_INDO_WESTREN,
            customIndowestren: response["data"]["details"],
          });
        } else {
          dispatch({
            type: types.GET_INDO_WESTREN,
            customIndowestren: [],
          });
        }
        resolve(response);
      })

      .catch((error) => {
        reject(error);
      });
  });
};
export const getSardis = () => (dispatch, getStore) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: types.FETCH_PRODUCTS_BEGIN,
    });

    instanceOne
      .get("product/sadris/all")
      .then((response) => {
        if (response["data"]["status"]) {
          dispatch({
            type: types.GET_SADRIES,
            customSadri: response["data"]["details"],
          });
        } else {
          dispatch({
            type: types.GET_SADRIES,
            customSadri: [],
          });
        }
        resolve(response);
      })

      .catch((error) => {
        reject(error);
      });
  });
};

export const getRecShoes = (userId) => (dispatch, getStore) => {
  let allShoes = [];

  return new Promise((resolve, reject) => {
    dispatch({
      type: types.FETCH_PRODUCTS_BEGIN,
    });
    instanceOne
      .get("/product/shoes/all")
      .then((response) => {
        if (response["data"]["status"]) {
          allShoes = response["data"]["details"];
          return instanceOne.get("recommendation/products/shoes/" + userId);
        } else {
        }
        resolve(response);
      })

      .then((response) => {
        if (!_.isNil(response)) {
          let fliterItems = [];
          if (response["data"]["status"]) {
            response["data"]["details"].forEach((element) => {
              let item = allShoes.find((el) => el._id === element.productId);
              fliterItems.push(item);
            });
            dispatch({
              type: types.GET_REC_SHOES,
              recShoes: fliterItems,
            });
          }
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const getRecShirts = (userId) => (dispatch, getStore) => {
  let allShirts = [];
  return new Promise((resolve, reject) => {
    dispatch({
      type: types.FETCH_PRODUCTS_BEGIN,
    });
    instanceOne
      .get("/product/shirts/all")
      .then((response) => {
        if (response["data"]["status"]) {
          allShirts = response["data"]["details"];
          return instanceOne.get("recommendation/products/shirts/" + userId);
        } else {
        }
        resolve(response);
      })

      .then((response) => {
        console.log("Rec Shirts", response);
        if (!_.isNil(response)) {
          let fliterItems = [];
          if (response["data"]["status"]) {
            response["data"]["details"].forEach((element) => {
              let item = allShirts.find((el) => el._id === element.productId);
              fliterItems.push(item);
            });
            dispatch({
              type: types.GET_REC_SHIRTS,
              recShirts: fliterItems,
            });
          }
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const getRecTrousers = (userId) => (dispatch, getStore) => {
  let allTrousers = [];
  return new Promise((resolve, reject) => {
    dispatch({
      type: types.FETCH_PRODUCTS_BEGIN,
    });
    instanceOne
      .get("/product/trousers/all")
      .then((response) => {
        if (response["data"]["status"]) {
          allTrousers = response["data"]["details"];
          return instanceOne.get("recommendation/products/trousers/" + userId);
        } else {
        }
        resolve(response);
      })

      .then((response) => {
        if (!_.isNil(response)) {
          let fliterItems = [];
          if (response["data"]["status"]) {
            response["data"]["details"].forEach((element) => {
              let item = allTrousers.find((el) => el._id === element.productId);
              fliterItems.push(item);
            });
            dispatch({
              type: types.GET_REC_TROUSERS,
              recTrousers: fliterItems,
            });
          }
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
