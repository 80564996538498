import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import CartPage from "../components/common/headers/common/cart-header";
import { removeFromCart } from "../actions";
import { logout } from "../actions/user";
import { getCartTotal } from "../services";
import "./style.scss";
import _ from "lodash";
import { psgurl } from "../api/axios";
import profileIcon from "../assets/profile_icon.png";

const CartContainer = ({
  cartList,
  total,
  symbol,
  removeFromCart,
  userInfo,
  history,
  logout,
}) => (
  <React.Fragment>
    {console.log(userInfo)}
    <li className="onhover-div">
      <div className="cart-qty-cls">{cartList.length}</div>
      <Link to={`${process.env.PUBLIC_URL}/cart`}>
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/cart.svg`}
          width="30px"
          alt=""
          className="img-fluid"
        />
        {/* <i className="fa fa-shopping-cart" /> */}
      </Link>

      <ul
        className="show-div shopping-cart"
        style={{ marginLeft: "-60px !important" }}
      >
        {cartList.map((item, index) => (
          <CartPage
            key={index}
            item={item}
            total={total}
            symbol={symbol}
            removeFromCart={() => removeFromCart(item)}
          />
        ))}
        {cartList.length > 0 ? (
          <div>
            <li>
              <div className="total">
                <h5>
                  subtotal :{" "}
                  <span>
                    {symbol}
                    {total}
                  </span>
                </h5>
              </div>
            </li>
            <li>
              <div className="buttons">
                <Link
                  to={`${process.env.PUBLIC_URL}/cart`}
                  className="view-cart"
                >
                  view cart
                </Link>
                <Link
                  to={`${process.env.PUBLIC_URL}/addresses`}
                  className="checkout"
                >
                  Proceed
                </Link>
              </div>
            </li>
          </div>
        ) : (
          <li>
            <h5>Your cart is currently empty.</h5>
          </li>
        )}
      </ul>
    </li>
    {!_.isEmpty(userInfo) ? (
      <li className="onhover-div">
        <div className="user-profile-actions">
          {_.isEmpty(userInfo.images["profile"]) ? (
            <div
              className="profile-avatar"
              style={{
                backgroundImage: `url(${profileIcon})`,
              }}
            />
          ) : (
            <div
              className="profile-avatar"
              style={{
                backgroundImage: `url(${userInfo.images["profile"]}`,
              }}
            />
          )}
        </div>
        <ul
          className="show-div shopping-cart nav-menu"
          style={{ minWidth: "150px" }}
        >
          <ul className="">
            {_.has(userInfo, "tags") ? (
              <React.Fragment>
                {_.isEmpty(userInfo["tags"]) ? (
                  <React.Fragment>
                    <li>
                      <a href={`${psgurl}/main/profile`}>Digital Profile</a>
                    </li>
                    <li>
                      <a href={`${psgurl}/measurment/${userInfo._id}`}>
                        Measurements
                      </a>
                    </li>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {console.log(userInfo["tags"][0])}
                    {userInfo["tags"][0] === "shopNowUser" ? (
                      <React.Fragment>
                        <li>
                          <a href={`${psgurl}/measurment/${userInfo._id}`}>
                            Measurements
                          </a>
                        </li>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <li>
                          <a href={`${psgurl}/main/profile`}>Digital Profile</a>
                        </li>
                     
                         <li>
                          <a href={`${psgurl}/measurment/${userInfo._id}`}>
                            Measurements
                          </a>
                        </li>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <li>
                  <a href={`${psgurl}/main/profile`}>Digital Profile</a>
                </li>
                <li>
                  <a href={`${psgurl}/measurment/${userInfo._id}`}>
                    Measurements
                  </a>
                </li>
              </React.Fragment>
            )}
            <li>
              <Link to={`${process.env.PUBLIC_URL}/orders`}>Order History</Link>
            </li>

            <li>
              <button
                style={{
                  border: "none",
                  backgroundColor: "white",
                  padding: "0px",
                  color: "#007bff",
                }}
                onClick={() => {
                  logout();
                  history.replace("/");
                  // window.location.reload();
                  // localStorage.clear();
                }}
              >
                Logout
              </button>
            </li>
          </ul>
        </ul>
      </li>
    ) : (
      <div className="ml-4">
        <Link to="/auth" className="btn btn-solid p-2">
          Login
        </Link>
      </div>
    )}
  </React.Fragment>
);

function mapStateToProps(state) {
  return {
    cartList: state.cartList.cart,
    total: getCartTotal(state.cartList.cart),
    symbol: state.data.symbol,
    userInfo: state.userData.userData,
  };
}

export default connect(
  mapStateToProps,
  { removeFromCart, logout }
)(withRouter(CartContainer));
