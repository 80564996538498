import React, { Component } from "react";
import { withTranslate } from "react-redux-multilingual";
// Custom Components
import HeaderOne from "./common/headers/header-one";
// import HeaderTwo from "./common/headers/header-two";
// import HeaderThree from "./common/headers/header-three";
//import FooterOne from "./common/footers/footer-one";
import FooterOne from "../components/Ui/footer.jsx";
import { connect } from "react-redux";
// import FooterTwo from "./common/footers/footer-two";
// import FooterThree from "./common/footers/footer-three";
import { ToastContainer } from "react-toastify";

// ThemeSettings
//import ThemeSettings from "./common/theme-settings";
import ThemeSettings from "../components/Ui/stylishSlider";
import _ from "lodash";

class App extends Component {
  render() {
    const { stylistData } = this.props;
    return (
      <div>
        <HeaderOne logoName={"logo.png"} />
        {this.props.children}
        <FooterOne logoName={"logo.png"} />
        {!_.isEmpty(stylistData) ? (
          <React.Fragment>
            {!_.isEmpty(stylistData["stylist"]) && <ThemeSettings />}
          </React.Fragment>
        ) : (
          <React.Fragment />
        )}
        <ToastContainer />
      </div>
    );
  }
}

const mapToStateProps = (state) => {
  return {
    stylistData: state.userData.stylistData,
    userData: state.userData.userData,
  };
};

export default withTranslate(
  connect(
    mapToStateProps,
    null
  )(App)
);
