import React, { Component } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import { roundDecimals } from "../../services";
import "react-toastify/dist/ReactToastify.min.css";


class ProductListItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      stock: "InStock",
      quantity: 1,
      image: "",
    };
  }

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  onClickHandle(img) {
    this.setState({ image: img });
  }

  minusQty = () => {
    if (this.state.quantity > 1) {
      this.setState({ stock: "InStock" });
      this.setState({ quantity: this.state.quantity - 1 });
    }
  };

  plusQty = () => {
    if (this.props.product.stock >= this.state.quantity) {
      this.setState({ quantity: this.state.quantity + 1 });
    } else {
      this.setState({ stock: "Out of Stock !" });
    }
  };
  changeQty = (e) => {
    this.setState({ quantity: parseInt(e.target.value) });
  };

  addToCart = (item) => {
    let discPrice = 0;
    let size = "";
    let price = 0;

    if (_.has(item, "options")) {
      if (_.isEmpty(item.options)) {
        discPrice = roundDecimals(item.discPrice);
        price = roundDecimals(item.price);
        size = "";
      } else {
        if (_.isEmpty(this.state.size)) {
          discPrice = roundDecimals(item.options[0]["options"][0].discPrice);
          price = roundDecimals(item.options[0]["options"][0].price);
          size = item.options[0]["options"][0].value;
        } else {
          discPrice = roundDecimals(this.state.size.discPrice);
          price = roundDecimals(this.state.size.price);
          size = this.state.size.value;
        }
      }
    }

    let cartItem = {
      producttypeId: item.producttype[0]["_id"],
      itemId: item._id,
      name: item.name,
      styles: [],
      qty: 1,
      size: size,
      price: price,
      discPrice: discPrice,
      disc: item.discount,
      isPer: item.isPer ? item.isPer : false,
      deliveryDays: item.deliveryDays,
      catId: item.category[0]["_id"],
      images: item.images,
    };
    if (!_.isEmpty(item.producttype)) {
      if (item.producttype[0]["name"] === "Custom Made") {
        this.props.onAddToCartClicked(cartItem, this.state.quantity);
      } else if (item.producttype[0]["name"] === "Ready Made") {
        if (!_.isEmpty(item.options)) {
          // if (_.isEmpty(this.state.size)) {
          //   toast.warn("Please select size");
          // } else {
          //   this.props.onAddToCartClicked(cartItem, this.state.quantity);
          // }
          this.props.onAddToCartClicked(cartItem, this.state.quantity);
        } else {
          this.props.onAddToCartClicked(cartItem, this.state.quantity);
        }
      }
    }
  };

  render() {
    const { product, symbol, onAddToWishlistClicked } = this.props;

    return (
      <div className="product-box">
        <div className="img-wrapper">
          <div className="front">
            {/* <Link
              to={`${process.env.PUBLIC_URL}/product/${
                product._id
              }`}
            > */}
            <Link
              to={{
                search: `?search=${this.props.searchKey}`,
                pathname: `${process.env.PUBLIC_URL}/product/${product._id}`,
              }}
            >
              <img
                src={
                  product.images
                    ? this.state.image
                      ? this.state.image
                      : product.images[0]
                    : product.images[0]
                }
                className="img-fluid"
                alt=""
              />
            </Link>
          </div>
          <div className="cart-info cart-wrap">
            <button title="Add to cart" onClick={() => this.addToCart(product)}>
              <i className="fa fa-shopping-cart" aria-hidden="true" />
            </button>
            <a
              href="javascript:void(0)"
              title="Add to Wishlist"
              onClick={()=>{}}
            >
              <i className="fa fa-heart" aria-hidden="true" />
            </a>
          </div>
        </div>
        <div className="product-detail" style={{paddingLeft:"10px"}}>
          <div className="mt-4">
            <Link
              to={{
                search: `?search=${this.props.searchKey}`,
                pathname: `${process.env.PUBLIC_URL}/product/${product._id}`,
              }}
            >
              <h6>{product.name}</h6>
            </Link>
            <h4>
              {_.isEmpty(product.options) ? (
                <React.Fragment>
                  {symbol}
                  {roundDecimals(product.discPrice)}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {symbol}
                  {roundDecimals(product.options[0]["options"][0]["discPrice"])}
                </React.Fragment>
              )}
            </h4>
          </div>
        </div>
      </div>
    );
  }
}

export default ProductListItem;
