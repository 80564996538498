import {
  FETCH_SINGLE_PRODUCT,
  CHANGE_CURRENCY,
  RECEIVE_PRODUCTS,
  FETCH_ALLPRODUCTS_BEGIN,
  RECEIVE_ALLPRODUCTS,
  REQ_MASTER_ATTR,
  GET_MASTER_ATTR,
  GET_CUSTOM_SHIRTS,
  GET_SHOES,
  GET_TROUSERS,
  GET_SUITS,
  GET_REC_SHOES,
  GET_CHINOS,
  GET_KURTHAS,
  GET_INDO_WESTREN,
  GET_SADRIES,
  GET_BLAZERS,
  GET_REC_SHIRTS,
  GET_REC_TROUSERS,
  USER_LOGOUT,
} from "../constants/ActionTypes";

const initialState = {
  allProducts: [],
  customShirts: [],
  customTrousers: [],
  customSuits: [],
  customChinos: [],
  customKurthas: [],
  customIndowestren: [],
  customSadri: [],
  customBlazers: [],
  shoes: [],
  recShoes: [],
  recShirts: [],
  recTrousers: [],
  products: [],
  symbol: "₹",
  product_details: [],
  masterAttibutes: {},
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALLPRODUCTS_BEGIN:
      return { ...state };
    case RECEIVE_ALLPRODUCTS:
      return { ...state, allProducts: action.allProducts };
    case REQ_MASTER_ATTR:
      return { ...state };
    case GET_MASTER_ATTR:
      return { ...state, masterAttributes: action.masterAttrs };
    case RECEIVE_PRODUCTS:
      return { ...state, products: action.products };
    case GET_CUSTOM_SHIRTS:
      return { ...state, customShirts: action.customShirts };
    case GET_TROUSERS:
      return { ...state, customTrousers: action.customTrousers };
    case GET_SUITS:
      return { ...state, customSuits: action.customSuits };
    case GET_CHINOS:
      return { ...state, customChinos: action.customChinos };
    case GET_KURTHAS:
      return { ...state, customKurthas: action.customKurthas };
    case GET_INDO_WESTREN:
      return { ...state, customIndowestren: action.customIndowestren };
    case GET_SADRIES:
      return { ...state, customSadri: action.customSadri };
    case GET_BLAZERS:
      return { ...state, customBlazers: action.customBlazers };
    case GET_SHOES:
      return { ...state, shoes: action.shoes };
    case GET_REC_SHOES:
      return { ...state, recShoes: action.recShoes };
    case GET_REC_SHIRTS:
      return { ...state, recShirts: action.recShirts };
    case GET_REC_TROUSERS:
      return { ...state, recTrousers: action.recTrousers };
    case USER_LOGOUT:
      return {
        ...state,
        recShoes: [],
        recShirts: [],
        recTrousers: [],
      };
    case FETCH_SINGLE_PRODUCT:
      if (
        state.products.findIndex(
          (product) => product.id === action.productId
        ) !== -1
      ) {
        const singleItem = state.products.reduce((itemAcc, product) => {
          return product;
        }, []);
        return { ...state, product_details: singleItem };
      }
      break;
    case CHANGE_CURRENCY:
      return { ...state, symbol: action.symbol };
    default:
      return state;
  }
};

export const recProductsReducer = () => {};

export default productReducer;
