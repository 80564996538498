import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  DECREMENT_QTY,
} from "../constants/ActionTypes";

export default function cartReducer(
  state = {
    cart: [],
  },
  action
) {
  switch (action.type) {
    case ADD_TO_CART:
      const productId = action.product.itemId;
      if (
        state.cart.findIndex((product) => product.itemId === productId) !== -1
      ) {
        const cart = state.cart.reduce((cartAcc, product) => {
          if (product.itemId === productId) {
            cartAcc.push({
              ...product,
              qty: product.qty + 1,
              sum: product.discPrice * (product.qty + 1),
            }); // Increment qty
          } else {
            cartAcc.push(product);
          }

          return cartAcc;
        }, []);

        return { ...state, cart };
      }

      return {
        ...state,
        cart: [
          ...state.cart,
          {
            ...action.product,
            qty: action.qty,
            sum: action.product.discPrice * action.qty,
          },
        ],
      };

    case DECREMENT_QTY:
      if (
        state.cart.findIndex(
          (product) => product.itemId === action.productId
        ) !== -1
      ) {
        const cart = state.cart.reduce((cartAcc, product) => {
          if (product.itemId === action.productId && product.qty > 1) {
            cartAcc.push({
              ...product,
              qty: product.qty - 1,
              sum: product.discPrice * (product.qty - 1),
            }); // Decrement qty
          } else {
            cartAcc.push(product);
          }

          return cartAcc;
        }, []);

        return { ...state, cart };
      }

      return {
        ...state,
        cart: [
          ...state.cart,
          {
            ...action.product,
            qty: action.qty,
            sum: action.product.discPrice * action.qty,
          },
        ],
      };

    case REMOVE_FROM_CART:
      return {
        cart: state.cart.filter(
          (item) => item.itemId !== action.product_id.itemId
        ),
      };

    default:
  }
  return state;
}
