import React, { Component } from "react";
import { Link } from "react-router-dom";

import { SlideUpDown } from "../../services/script";
import LogoImage from "../common/headers/common/logo";
import { psgurl } from "../../api/axios";
const links = [
  { name: "Profile", url: "/main/profile" },
  { name: "Styling", url: "/main/mystyling" },
  { name: "Grooming", url: "/main/grooming" },
  { name: "Measurements", url: "/measurment" },
  { name: "Wardrobe", url: "/main/wardrobe" },
];

class FooterComponent extends Component {
  componentDidMount() {
    var contentwidth = window.innerWidth;
    if (contentwidth < 750) {
      SlideUpDown("footer-title");
    } else {
      var elems = document.querySelectorAll(".footer-title");
      [].forEach.call(elems, function(elemt) {
        let el = elemt.nextElementSibling;
        el.style = "display: block";
      });
    }
  }

  render() {
    return (
      <footer className="footer-light">
        
        <section className="section-b-space light-layout">
          <div className="container">
            <div className="row footer-theme partition-f">
              <div className="col-lg-6 col-md-6">
                <div className="footer-title footer-mobile-title">
                  <h4>about</h4>
                </div>
                <div className="footer-contant">
                  <div className="footer-logo">
                    <LogoImage logo={this.props.logoName} />
                  </div>
                  <p>
                    My Perfect Fit is focussed on revolutionising the fashion
                    and clothing space with innovative ideas and delivering
                    highly personalised experiences driven through technology.
                    It is a unique fashion retail company providing complete
                    solutions to elevate your look and personality by providing
                    well fitted, highly personalised outfits and accessories.
                    The clothing service is well complemented by styling and
                    wardrobe offerings to provide you highly personalised
                    recommendations.
                  </p>

                  <div className="row">
                    <div className="col-12 mt-4 mb-2">
                      <h6
                        style={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        Store Location
                      </h6>{" "}
                      <p>
                        MyPerfect Fit First Floor, Plot No.1108, Road Number 55,
                        opp. Peddamma Temple , Jubilee Hills, Hyderabad,
                        Telangana 500033, 080083 29992.
                      </p>
                    </div>
                  </div>

                  <div className="footer-social">
                    <ul>
                      <li>
                        <a href="https://www.facebook.com/myperfectfitformen">
                          <i className="fa fa-facebook" aria-hidden="true" />
                        </a>
                      </li>

                      <li>
                        <a
                          href={
                            "https://www.linkedin.com/company/myperfectfit/"
                          }
                        >
                          <i className="fa fa-linkedin" aria-hidden="true" />
                        </a>
                      </li>

                      <li>
                        <a
                          href={
                            "https://youtu.be/O_SLpUNjj80"
                          }
                        >
                          <i
                            className="fa fa-youtube-play"
                            aria-hidden="true"
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href={"https://www.instagram.com/myperfectfitformen/"}
                        >
                          <i className="fa fa-instagram" aria-hidden="true" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col offset-xl-1">
                <div className="sub-title">
                  <div className="footer-title">
                    <h4>My Account </h4>
                  </div>
                  <div className="footer-contant">
                    <ul>
                      {links.map((link) => (
                        <li>
                          <a href={`${psgurl}${link.url}`}>{link.name}</a>
                        </li>
                      ))}
                      <li>
                        <Link to={`${process.env.PUBLIC_URL}/`}>Shopping</Link>
                      </li>
                      <li>
                        <Link to={`${process.env.PUBLIC_URL}/orders`}>
                          Orders
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="sub-footer ">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-md-6 col-sm-12">
                <div className="footer-end">
                  <p>
                    <i className="fa fa-copyright" aria-hidden="true">
                      {" "}
                      {new Date().getFullYear()}
                    </i>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default FooterComponent;

