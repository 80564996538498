import React, { Component } from "react";
import { withTranslate } from "react-redux-multilingual";
import { connect } from "react-redux";
import { SlideUpDown } from "../../services/script";
// import { ToastContainer } from "react-toastify";
import "./style.scss";

class ThemeSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      call: false,
    };
  }

  openSetting = () => {
    document.getElementById("setting_box").classList.add("open-setting");
    document.getElementById("setting-icon").classList.add("open-icon");
  };
  closeSetting = () => {
    document.getElementById("setting_box").classList.remove("open-setting");
    document.getElementById("setting-icon").classList.remove("open-icon");
  };

  /*=====================
     Tap on Top
     ==========================*/
  componentWillMount() {
    window.addEventListener("scroll", this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleScroll = () => {
    if (document.documentElement.scrollTop > 600) {
      document.querySelector(".tap-top").style = "display: block";
    } else {
      document.querySelector(".tap-top").style = "display: none";
    }
  };
  clickToTop() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }

  componentDidMount() {
    SlideUpDown("setting-title");
  }

  // Color Picker

  render() {
    let tap_to_top = { display: "none" };
    const { data } = this.props;
    return (
      <div>
        <React.Fragment>
          <div className="stylis-icon-section" onClick={() => this.openSetting()}>
            <div
              className="small-icon"
              style={{
                backgroundImage: `url(${data["stylist"][0]["image"]})`,
              }}
            />
            <div>
              <label class="mb-0">Stylist</label>
            </div>
          </div>
          <a href="javascript:void(0);" onClick={() => this.openSetting()} style={{display:"none"}}>
            <div
              className="setting-sidebar"
              id="setting-icon"
              style={{
                height: "65px",
                width: "65px",
                backgroundColor: "transparent",
              }}
            >
              <div
                className="small-icon"
                style={{
                  backgroundImage: `url(${data["stylist"][0]["image"]})`,
                }}
              />
              <div>
                <label class="mb-0">Stylist</label>
              </div>
            </div>
          </a>
          <div id="setting_box" className="setting-box">
            <a
              href="javascript:void(0)"
              className="overlay"
              onClick={() => this.closeSetting()}
            />
            <div className="setting_box_body">
              <div onClick={() => this.closeSetting()}>
                <div className="sidebar-back text-left">
                  <i className="fa fa-angle-left pr-2" aria-hidden="true" />{" "}
                  Back
                </div>
              </div>
              <div className="setting-body">
                <div className="stylist-section">
                  <div className="image-section">
                    <div
                      className="image"
                      style={{
                        backgroundImage: `url(${data["stylist"][0]["image"]})`,
                      }}
                    />
                    <h3 className="person-name mb-1">
                      {data["stylist"][0]["name"]}
                    </h3>
                    <p className="designation m-0">Personal Stylist</p>
                    <div className="social-icons-section">
                      <img
                        onClick={() => {
                          this.setState({ call: !this.state.call });
                        }}
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/images/phone.svg`}
                        width="25px"
                      />

                      <img
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/images/whatsapp.svg`}
                        width="25px"
                      />
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/mail.svg`}
                        width="25px"
                      />

                      {/* <i
                        className="fa fa-whatsapp mr-2 color-white"
                        style={{ fontSize: "22px" }}
                        aria-hidden="true"
                      />

                      <i
                        className="fa fa-envelope mr-2 color-white"
                        style={{ fontSize: "22px" }}
                        aria-hidden="true"
                      />
                      <i
                        className="fa fa-desktop mr-2 color-white"
                        style={{ fontSize: "22px" }}
                        aria-hidden="true"
                      /> */}
                    </div>
                  </div>
                  {this.state.call && (
                    <div
                      style={{
                        backgroundColor: "red",
                        textAlign: "center",
                        padding: "10px",
                        color: "white",
                      }}
                    >
                      Call: {data["stylist"][0]["phone"]}
                    </div>
                  )}

                  <p className="description p-3 mt-2 mb-2">
                    {data["stylist"][0]["note"]}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="tap-top" onClick={this.clickToTop} style={tap_to_top}>
            <div>
              <i className="fa fa-angle-double-up" />
            </div>
          </div>
        </React.Fragment>
      </div>
    );
  }
}

const mapToDispatchPops = (state) => {
  return {
    data: state.userData.stylistData,
  };
};

export default connect(
  mapToDispatchPops,
  null
)(withTranslate(ThemeSettings));
