import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Breadcrumb from "../../components/Ui/breadcrumb";

import { getUserOrders } from "../../actions/user";
import { connect } from "react-redux";
import {withRouter} from 'react-router-dom';
import { LoaderComponent } from "../../components/Ui/loader";
import { EmptyDataComponent } from "../../components/Ui/emptydata";
import OrderCard from "../../components/Ui/orderCard";
import "./styles.scss";
import _ from "lodash";

class OrderRoute extends Component {
  state = {
    layoutColumns: 3,
    isLoading: false,
  };

  componentDidMount() {
    if (!_.isEmpty(this.props.userData)) {
      this.setState({ isLoading: true });
      this.props
        .getUserOrders(this.props.userData["_id"])
        .then((response) => {
          this.setState({ isLoading: false });
        })
        .catch((err) => {
          this.setState({ isLoading: false });
        });
    }
  }

  render() {
    const { orders ,history} = this.props;
    return (
      <div className="orders-component">
        {/*SEO Support*/}
        <Helmet>
          <title>MPF Fashions | Orders</title>
          <script src={`${process.env.PUBLIC_URL}/assets/revchat.js`} type="text/javascript" />

          {/* <meta
            name="description"
            content="Multikart – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Multikart Bootstrap 4 Template will help you run multiple businesses."
          /> */}
        </Helmet>
        {/*SEO Support End */}

        <Breadcrumb title={"Orders"} />

        <section className="section-b-space pt-0">
          <div className="container">
            <div className="row">
              <div className="collection-content col-12 p-0">
                <div className="page-main-content ">
                  <div className="">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="collection-product-wrapper">
                          {this.state.isLoading ? (
                            <LoaderComponent />
                          ) : (
                            <React.Fragment>
                              {_.isEmpty(orders) ? (
                                <EmptyDataComponent
                                  onClick={()=>history.push('/')}
                                  data={{
                                    title: "No orders found !",
                                    subTitle: "",
                                    btnName: "Continue Shopping",
                                   
                                  }}
                                />
                              ) : (
                                
                                  <div className="p-3">
                                    <OrderCard orders={orders}/>
                                  </div>
                                
                              )}
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    userData: state.userData.userData,
    orders: state.userData.orders,
  };
};

const mapToDispatch = (dispatch) => {
  return {
    getUserOrders: (userId) => dispatch(getUserOrders(userId)),
  };
};

export default connect(
  mapStateToProps,
  mapToDispatch
)(withRouter(OrderRoute));
