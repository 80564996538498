import React, { Component } from "react";
import { Tab, Tabs, TabList } from "react-tabs";
import "react-tabs/style/react-tabs.scss";
import "./style.scss";
import { connect } from "react-redux";
import _ from "lodash";

class SwitchTabs extends Component {
  render() {
    const { user } = this.props;
    return (
      <div className="switch-component">
        <section className="tab-product m-0">
          <div className="row">
            <div className="col-sm-12 col-lg-12">
              <Tabs className="tab-content nav-material">
                <TabList className="nav nav-tabs nav-material">  
                  <Tab className="nav-item">
                    <span className="nav-link">
                      <i className="icofont icofont-man-in-glasses" />
                      All Products
                    </span>
                    <div className="material-border" />
                  </Tab>
                  <Tab className="nav-item">
                    <span className="nav-link active">
                      <i className="icofont icofont-ui-home" />
                      {_.isEmpty(user) ? "For you" : `For ${user.firstName}`}
                    </span>
                    <div className="material-border" />
                  </Tab>
                </TabList>
                {this.props.children}
              </Tabs>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userData.userData,
  };
};

export default connect(
  mapStateToProps,
  null
)(SwitchTabs);
