import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Breadcrumb from "../../components/Ui/breadcrumb";
import { getUserOrders } from "../../actions/user";
import { connect } from "react-redux";
// import { LoaderComponent } from "../../components/Ui/loader";
// import { EmptyDataComponent } from "../../components/Ui/emptydata";
// import OrderCard from "../../components/Ui/orderCard";
import "./styles.scss";
import _ from "lodash";
import { psgurl } from "../../api/axios";

class OrderRoute extends Component {
  state = {
    layoutColumns: 3,
    isLoading: false,
  };

  componentDidMount() {
    if (!_.isEmpty(this.props.userData)) {
      this.setState({ isLoading: true });
      this.props
        .getUserOrders(this.props.userData["_id"])
        .then((response) => {
          this.setState({ isLoading: false });
        })
        .catch((err) => {
          this.setState({ isLoading: false });
        });
    }
  }

  dateCount = (days) => {
    var date = new Date(Date.now() + days * 86400000);
    return date.toString();
  };

  render() {
    const { order, symbol } = this.props;

    return (
      <div className="orders-component">
        {/*SEO Support*/}
        <Helmet>
          <title>MPF Fashions | Order</title>
          <script src={`${process.env.PUBLIC_URL}/assets/revchat.js`} type="text/javascript" />

          {/* <meta
            name="description"
            content="Multikart – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Multikart Bootstrap 4 Template will help you run multiple businesses."
          /> */}
        </Helmet>
        {/*SEO Support End */}

        <Breadcrumb title={"Order Details"} />

        <section className="section-b-space pt-0">
          <div className="container">
            <div className="row">
              <div className="collection-content col-12 p-0">
                <div className="page-main-content ">
                  {!_.isNil(order) ? (
                    <div className="row">
                      <div className="col-12">
                        <div className="product-order">
                          {/* <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <h6>Order Summary</h6>
                          <a
                            className="btn btn-solid"
                            // href={`${psgurl}/measurment`}
                          >
                            Add/Update your Measurements
                          </a>
                        </div> */}
                          <div className="row product-order-detail" key={0}>
                            <div className="col-12 order-summary-component">
                              {order.items.map((order) => (
                                <div className="order-item-card mb-4">
                                  <div className="mr-3">
                                    <img
                                      alt={""}
                                      src={`${order.images[0]}`}
                                      className="img-fluid img-section"
                                    />
                                  </div>
                                  <div>
                                    <h4
                                      style={{
                                        fontWeight: "bold",
                                        color: "black",
                                      }}
                                    >
                                      {order.name}
                                    </h4>

                                    <h6
                                      className="m-0"
                                      style={{
                                        color: "black",
                                      }}
                                    >
                                      <b>
                                        Price :
                                        <span
                                          style={{
                                            display: "inline",
                                            color: "#e17055",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {symbol}
                                          {order.discPrice}
                                        </span>
                                      </b>
                                    </h6>
                                    <span>
                                      Qty : <b> {order.qty}</b>
                                    </span>
                                    {!_.isEmpty(order.size) && (
                                      <span>
                                        Size : <b>{order.size}</b>
                                      </span>
                                    )}
                                    <span style={{ display: "block" }}>
                                      DeliveryDate :
                                      {this.dateCount(order.deliveryDays)}
                                    </span>
                                  </div>
                                  <hr />
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="row product-order-detail">
                            <div className="col-12 order-summary-component p-2">
                              <div className="order-header">
                                <h4
                                  style={{
                                    fontWeight: "bold",
                                    color: "#000000",
                                  }}
                                >
                                  Sub Total
                                </h4>
                                <h4
                                  style={{
                                    fontWeight: "bold",
                                    color: "#000000",
                                  }}
                                >
                                  {symbol}
                                  {order.orderTotal}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="row order-success-sec p-2">
                          <div className="col-12 mt-4">
                            <h4>Order Summary</h4>
                            <ul className="order-detail">
                              <li>
                                <b>Order Id: </b>
                                {order.prefix}
                                {order.orderId}
                              </li>
                              <li>
                                <b>Order Total:</b> {symbol}
                                {order.orderTotal}
                              </li>
                              <li>
                                <b>Payment Id</b>: {order.razorPayId}
                              </li>
                              <li>
                                <b>Date</b>: {order.orderDateTime.month}/
                                {order.orderDateTime.day}/
                                {order.orderDateTime.year}
                              </li>
                              {/* <li>Status:Delivered</li> */}
                            </ul>
                          </div>
                          <div className="col-12">
                            <hr />
                          </div>
                          <div className="col-12 mt-4 p-2">
                            <h4>Shipping address</h4>
                            <ul className="order-detail">
                              <li>
                                {/* {order.address.firstName}
                              {order.address.lastName} */}
                                <b>Name</b>: {order.address.firstName}{" "}
                                {order.address.lastName}
                              </li>
                              <li>
                                <b>Address</b> : {order.address.address1},
                                {order.address.address2},
                                {order.address.landmark}
                                {order.address.city},{order.address.state},
                                {order.address.country},
                                {order.address.postalCode}
                              </li>

                              <li>
                                {/* {order.address.address1},{order.address.address2} */}
                                <b>Phone</b> : {order.phone}
                              </li>
                              <li>
                                {/* {order.address.address1},{order.address.address2} */}
                                <b>Email</b> : {order.email}
                              </li>

                              {/* <li>{order.address.email}</li>
                            <li>{order.address.phone}</li> */}
                            </ul>
                          </div>
                          <div className="col-12">
                            <hr />
                          </div>
                          <div className="col-12 mt-4 payment-mode p-2">
                            <h4>payment method</h4>
                            <p>
                              Pay on Delivery (Cash/Card). Cash on delivery
                              (COD) available. Card/Net banking acceptance
                              subject to device availability.
                            </p>
                          </div>
                          <div className="col-12 mt-4 payment-mode p-2">
                            <h4 className="mt-4">Add/Update Measurements</h4>
                            <a class="btn btn-solid mt-4"  href={`${psgurl}/measurment`} >Measurements</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <React.Fragment />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let productId = ownProps.match.params.id;
  let orderItem = state.userData.orders.find((el) => el._id === productId);

  return {
    userData: state.userData.userData,
    orders: state.userData.orders,
    order: orderItem,
    symbol: state.data.symbol,
  };
};

const mapToDispatch = (dispatch) => {
  return {
    getUserOrders: (userId) => dispatch(getUserOrders(userId)),
  };
};

export default connect(
  mapStateToProps,
  mapToDispatch
)(OrderRoute);
