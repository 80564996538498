import React from "react";
import "./style.scss";
import { isMobileOnly } from "react-device-detect";
export const TopServiceBannerComponent = () => {
  return (
    <div className="top-service-banner-component mb-4" style={{ paddingLeft: !isMobileOnly ? "30px" : "" ,paddingRight:'30px'}}>
      <div className="icon-section">
        <div className="service-icon">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/icon/tailor-icon.svg`}
            width="35px"
            alt="mpf_freeshipping"
          />
        </div>

        <div style={{ marginLeft: !isMobileOnly ? "30px" : "" }}>
          <h5>
            <b>Custom Made</b>
          </h5>
          {!isMobileOnly && <p>Made just for you - with easy measurements</p>}
        </div>
      </div>
      <div className="icon-section">
        <div className="service-icon">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/icon/suit.svg`}
            width="35px"
            alt="mpf_freeshipping"
          />
        </div>
        <div style={{ marginLeft: !isMobileOnly ? "30px" : "" }}>
          <h5>
            <b>Design by Stylist</b>
          </h5>
          {!isMobileOnly && (
            <p>Each outfits are styles by your personal stylist</p>
          )}
        </div>
      </div>
      <div className="icon-section">
        <div className="service-icon">
          <img
            src={`${
              process.env.PUBLIC_URL
            }/assets/images/icon/free-shipping.svg`}
            width="35px"
            alt="mpf_freeshipping"
          />
        </div>
        <div style={{ marginLeft: !isMobileOnly ? "30px" : "" }}>
          <h5>
            <b>Free Shiping</b>
          </h5>
          {!isMobileOnly && <p>Shipped to you in 7-10 days</p>}
        </div>
      </div>
    </div>
  );
};

export default TopServiceBannerComponent;
