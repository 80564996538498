import axios from "axios";

export const instanceOne = axios.create({
  baseURL: "https://api.myperfectfit.co.in:9633/v2/api",
  headers: { "Content-Type": "application/json" },
});
export const instanceTwo = axios.create({
  baseURL: "https://api.myperfectfit.co.in:3019/v2/api",
  headers: { "Content-Type": "application/json" },
});
//export const psgurl = "https://psg.myperfectfit.co.in";
export const psgurl = "http://psgtestserver.s3-website.ap-south-1.amazonaws.com";
//export const psgurl = "http://localhost:3000";
