import * as types from "../constants/ActionTypes";
import { instanceTwo, instanceOne } from "../api/axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import _ from "lodash";
import resolve from "resolve";
import { parseTwoDigitYear } from "moment";

export const userLogin = (phone, password) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: types.USER_LOGIN_START,
    });

    instanceTwo
      .post("/userapi/login", { userId: phone, password: password })
      .then((response) => {
        if (response["data"]["status"]) {
          localStorage.setItem(
            "log_name",
            `${response["data"]["firstName"]} ${response["data"]["lastName"]}`
          );
          localStorage.setItem("log_email", `${response["data"]["email"]}`);
          localStorage.setItem("log_phone", `${response["data"]["phone"]}`);
          return instanceTwo.get("/userapi/" + response["data"]["id"]);
        }
        resolve(response);
      })
      .then((response) => {
        if (!_.isNil(response)) {
          if (response["data"]["status"]) {
            dispatch({
              type: types.GET_USER_DETAILS,
              userData: response["data"]["details"],
            });
            return instanceOne.get(
              "/user/attributedetails/" + response["data"]["details"]["_id"]
            );
          }
        }
        resolve(response);
      })
      .then((response) => {
        if (!_.isNil(response)) {
          if (response["data"]["status"]) {
            if (!_.isEmpty(response["data"]["details"])) {
              dispatch({
                type: types.GET_STYLIST_INFO,
                stylistData: response["data"]["details"][0],
              });
              if (!_.isEmpty(response["data"]["details"][0]["stylist"])) {
                localStorage.setItem(
                  "log_stylist",
                  `${response["data"]["details"][0]["stylist"][0]["name"]}`
                );
              }
            }
          }
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const userSignup = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: types.USER_REGISTRATION_START,
    });

    instanceTwo
      .post("/userapi/register", payload)
      .then((response) => {
        resolve(response);
      })

      .catch((error) => {
        reject(error);
      });
  });
};

export const getUserAddresses = (userId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    instanceOne
      .get("/user/address/" + userId)
      .then((response) => {
        if (!_.isNil(response)) {
          dispatch({
            type: types.GET_USER_ADDRESSES,
            addresses: response["data"]["details"],
          });
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getSelectedAddress = (address) => {
  toast.success("Delivery address selected ");
  return (dispatch) => {
    dispatch({
      type: types.SELECTED_ADDRESS,
      deliveryAddress: address,
    });
  };
};

export const postNewAddress = (userId, payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    instanceOne
      .put("/user/address/" + userId, payload)
      .then((response) => {
        if (_.isNil(response)) {
          if (response["data"]["status"]) {
            dispatch({
              type: types.POST_USER_ADDRESS,
            });
            toast.success("Delivery address saved successfully");
          }
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateAddress = (userId, addressId, payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    instanceOne
      .put("/user/address/" + userId, payload)
      .then((response) => {
        if (!_.isNil(response)) {
          if (response["data"]["status"]) {
            dispatch({
              type: types.POST_USER_ADDRESS,
            });

            return instanceOne.delete(`/user/address/${userId}/${addressId}`);
          }
        }
        resolve(response);
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteAddress = (userId, itemId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    instanceOne
      .delete(`/user/address/${userId}/${itemId}`)
      .then((response) => {
        if (!_.isNil(response)) {
          if (response["data"]["status"]) {
            dispatch({
              type: types.DELETE_USER_ADDRESS,
            });
            toast.success("Delivery address deleted successfully");
          }
        }

        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getUserDetails = (userId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    instanceTwo
      .get(`/userapi/${userId}`)
      .then((response) => {
        if (!_.isNil(response)) {
          if (response["data"]["status"]) {
            localStorage.setItem(
              "log_name",
              `${response["data"]["details"]["firstName"]} ${
                response["data"]["details"]["lastName"]
              }`
            );
            localStorage.setItem(
              "log_email",
              `${response["data"]["details"]["email"]}`
            );
            localStorage.setItem(
              "log_phone",
              `${response["data"]["details"]["phone"]}`
            );
            dispatch({
              type: types.GET_USER_DETAILS,
              userData: response["data"]["details"],
            });

            return instanceOne.get("/user/attributedetails/" + userId);
          } else {
          }
        }
        resolve(response);
      })
      .then((response) => {
        if (!_.isNil(response)) {
          if (response["data"]["status"]) {
            if (!_.isEmpty(response["data"]["details"][0]["stylist"])) {
              localStorage.setItem(
                "log_stylist",
                `${response["data"]["details"][0]["stylist"][0]["name"]}`
              );
            }
            dispatch({
              type: types.GET_STYLIST_INFO,
              stylistData: response["data"]["details"][0],
            });
          }
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getUserOrders = (userId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    instanceOne
      .get(`order/product/user/${userId}`)
      .then((response) => {
        if (!_.isNil(response)) {
          if (response["data"]["status"]) {
            dispatch({
              type: types.GET_USER_ORDERS,
              orders: response["data"]["details"],
            });
          }
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const logout = () => {
  return (dispatch) =>
    dispatch({
      type: types.USER_LOGOUT,
    });
};




