import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Breadcrumb from "../../components/Ui/breadcrumb";
import { removeFromWishlist } from "../../actions";
import { getCartTotal } from "../../services";
import _ from "lodash";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { isMobileOnly } from "react-device-detect";
import { instanceOne } from "../../api/axios";
import Modal from "react-responsive-modal";
import { LoaderComponent } from "../../components/Ui/loader";

class checkOut extends Component {
  state = {
    isSuccess: false,
    open: false,
  };

  goToOrderSummary = () => {
    var self = this;
    self.setState({ open: false });
    self.props.history.push({
      pathname: "/orders",
    });
  };

  buynow = () => {
    if (_.isEmpty(this.props.deliveryAddress)) {
      toast.warn("Delivery address not found");
    } else {
      var self = this;
      const options = {
        key: "rzp_live_b0QHv1mxIL2uZp",
        amount: self.props.total * 100,

        name: "MPF FASHIONS",
        modal: {
          ondismiss: function() {
            self.setState({ isLoading: false });
          },
        },
        handler(Response) {
          const paymentId = Response.razorpay_payment_id;
          self.setState({ open: true });
          const payload = {
            razorPayId: paymentId,
            address: {
              ...self.props.deliveryAddress,
            },
            cart: {
              items: self.fliterCartItems(),
              gTotal: self.props.total,
              //gTotal:1,
              discTotal: 0,
              userId: self.props.userData._id,
              note: "NA",
            },
          };
          delete payload.address._id;
          instanceOne
            .post("/order/product/" + self.props.userData._id, payload)
            .then((response) => {
              if (response["data"]["status"]) {
                self.setState({ isSuccess: true });
                toast.success("Successfully placed order");
              }
            })
            .catch((error) => {
              self.setState({ isSuccess: false, open: false });
              toast.error("Something went to wrong please try again..");
            });
        },
        prefill: {
          name: "",
          email: self.props.deliveryAddress.email,
          contact: self.props.deliveryAddress.phone,
        },
        notes: {
          address: "",
        },
        theme: {
          color: "#9D50BB",
        },
      };
      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    }
  };

  fliterCartItems = () => {
    let cartItems = [...this.props.cartItems];
    cartItems.forEach(function(item) {
      delete item.sum;
    });
    return cartItems;
  };

  render() {
    const { cartItems, symbol, total, deliveryAddress } = this.props;

    // Paypal Integration

    return (
      <div>
        {/*SEO Support*/}
        <Helmet>
          <title>MPF | CheckOut</title>
          <script
            src={`${process.env.PUBLIC_URL}/assets/revchat.js`}
            type="text/javascript"
          />

          {/* <meta
            name="description"
            content="Multikart – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Multikart Bootstrap 4 Template will help you run multiple businesses."
          /> */}
        </Helmet>
        {/*SEO Support End */}

        <Breadcrumb title={"Checkout"} />

        <section className="section-b-space">
          <div className="container padding-cls">
            <div className="checkout-page">
              <div className="checkout-form">
                <form>
                  <div className="checkout row">
                    {!_.isEmpty(deliveryAddress) ? (
                      <div className="col-12 p-0 mb-3">
                        <div className="stripe-section">
                          <h5>Delivery Address</h5>
                          <div>
                            <h5 className="checkout_class">
                              {deliveryAddress.firstName}{" "}
                              {deliveryAddress.lastName}
                            </h5>

                            <p
                              className="mb-1"
                              style={{ textTransform: "none" }}
                            >
                              Phone : {deliveryAddress.phone}
                            </p>
                            <p
                              className="mb-1"
                              style={{ textTransform: "none" }}
                            >
                              email: {deliveryAddress.email}
                            </p>
                            <p
                              className="mb-1"
                              style={{ textTransform: "none" }}
                            >
                              Building Address : {deliveryAddress.address1},
                              {deliveryAddress.address2}
                            </p>
                            <p
                              className="mb-1"
                              style={{ textTransform: "none" }}
                            >
                              {deliveryAddress.city},{deliveryAddress.state},
                              {deliveryAddress.country},
                              {deliveryAddress.postalCode}
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="col-12 p-0 mb-3">
                        <Link
                          to={`${process.env.PUBLIC_URL}/addresses`}
                          className="btn-solid btn"
                        >
                          Choose Delivery Address
                        </Link>
                      </div>
                    )}
                    <div className="col-12 checkout-details">
                      <table className="table cart-table table-responsive-xs">
                        <thead>
                          <tr className="table-head">
                            <th style={{ borderBottom: "none" }} scope="col">
                              Image
                            </th>
                            <th style={{ borderBottom: "none" }} scope="col">
                              Product
                            </th>
                            <th style={{ borderBottom: "none" }} scope="col">
                              Price
                            </th>
                          </tr>
                        </thead>
                        {cartItems.map((item, index) => {
                          return (
                            <tbody key={index}>
                              <tr>
                                <td className="pt-3">
                                  <Link
                                    to={`${
                                      process.env.PUBLIC_URL
                                    }/left-sidebar/product/${item.id}`}
                                  >
                                    <img
                                      width="30px"
                                      style={{ marginTop: "10px" }}
                                      src={
                                        item.images
                                          ? item.images[0]
                                          : item.images[0]
                                      }
                                      alt=""
                                    />
                                  </Link>
                                </td>
                                <td className="pt-3 pl-2">
                                  <h4 style={{ marginTop: "10px" }}>
                                    {item.name}
                                  </h4>
                                  <p>Qty: {item.qty} </p>
                                </td>
                                <td className="pt-3 pl-2">
                                  <h4
                                    style={{ marginTop: "10px" }}
                                    className="td-color"
                                  >
                                    {symbol}
                                    {item.sum}
                                  </h4>
                                </td>
                              </tr>
                            </tbody>
                          );
                        })}
                        <tbody style={{ borderTop: "none" }}>
                          <tr>
                            <td>
                              {" "}
                              <h4 style={{ marginTop: "10px" }}>
                                <b>Total :</b>
                              </h4>
                            </td>
                            <td />
                            <td>
                              <h4 style={{ marginTop: "10px" }}>
                                <b>
                                  {symbol} {total}{" "}
                                </b>
                              </h4>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div
                      className="row cart-buttons"
                      style={{ width: "100%", marginTop: "20px" }}
                    >
                      <div
                        className={
                          isMobileOnly ? "col-12 text-center" : "col-6"
                        }
                      >
                        <Link
                          to={`${process.env.PUBLIC_URL}/`}
                          className="btn btn-solid mb-3"
                        >
                          continue shopping
                        </Link>
                      </div>
                      {total !== 0 && (
                        <div
                          className={
                            isMobileOnly
                              ? "col-12 text-center"
                              : "col-6 text-right"
                          }
                        >
                          <button
                            type="button"
                            onClick={this.buynow}
                            className="btn btn-solid mb-3"
                          >
                            Place Order
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

        <Modal
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
          closeOnOverlayClick={false}
          center
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body">
                {this.state.isSuccess ? (
                  <div className="row">
                    <div className="col-md-12">
                      <div className="success-text">
                        <i
                          className="fa fa-check-circle mb-2"
                          aria-hidden="true"
                        />
                        <h2>Thank you</h2>
                        <p>
                          Payment Is Has Been Received Order Placed Successfully
                        </p>
                      </div>
                      <div className="text-center mt-5">
                        <button
                          className="btn btn-solid"
                          onClick={() => this.goToOrderSummary()}
                        >
                          View Order
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div style={{ height: "250px", width: "250px" }}>
                    <LoaderComponent />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  cartItems: state.cartList.cart,
  symbol: state.data.symbol,
  total: getCartTotal(state.cartList.cart),
  deliveryAddress: state.userData.deliveryAddress,
  userData: state.userData.userData,
});

export default connect(
  mapStateToProps,
  { removeFromWishlist }
)(checkOut);
