import React from "react";


export const EmptyDataComponent = ({ data, onClick }) => {
  return (
    <div className="row">
      <div className="col-sm-12 text-center section-b-space mt-5 no-found">
        <img
          alt="search-empty"
          src={`${process.env.PUBLIC_URL}/assets/images/empty-search.jpg`}
          className="img-fluid mb-4"
        />
        <h3>{data.title}</h3>
        <p>{data.subTitle}</p>
        <button onClick={onClick} className="btn btn-solid">
          {data.btnName}
        </button>
      </div>
    </div>
  );
};
