import React, { Component } from "react";
import Slider from "react-slick";
import { connect } from "react-redux";
import { Product4, Product5 } from "../../services/script";
import { addToCart, addToWishlist, addToCompare } from "../../actions";
import _ from "lodash";

import ProductListItem from "./ProductItem";

class SimilarProducts extends Component {
  
  
  render() {
    const {
      products,
      symbol,
      addToCart,
      addToWishlist,
      addToCompare,
      type,
      searchKey,
    } = this.props;

    var properties;
    if (type === "kids") {
      properties = Product5;
    } else {
      properties = Product4;
    }

    return (
      <div>
        {/*Paragraph*/}
        <div className="mt-5 mb-3">
          <h2 className="mb-5 pl-3" style={{ fontSize: "18px" }}>
            {this.props.title}
          </h2>
        </div>

        {/*Paragraph End*/}
        <section className="section-b-space p-t-0">
          <div className="container">
            <div className="row">
              <div className="col">
                <Slider
                  {...properties}
                  className="product-4 product-m no-arrow"
                >
                  {products.map((product, index) => (
                    <div key={index}>
                      <ProductListItem
                        searchKey={searchKey}
                        product={product}
                        symbol={symbol}
                        onAddToCompareClicked={() => addToCompare(product)}
                        onAddToWishlistClicked={() => addToWishlist(product)}
                        onAddToCartClicked={addToCart}
                        key={index}
                      />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    symbol: state.data.symbol,
  };
};

export default connect(
  mapStateToProps,
  { addToCart, addToWishlist, addToCompare }
)(SimilarProducts);
